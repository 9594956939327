import React from "react";

import MiniNav from "../../../Components/MiniNav";
import AccountSettings from "./AccountSettings";
import SystemSettings from "./SystemSettings";

const Settings = () => {


  return (
    <div>
      {/* <h1 className="text-center text-3xl font-bold">Settings</h1> */}
      <MiniNav page={"Settings"} />


      <div className="flex flex-col  2xl:flex-row space-y-4 2xl:space-y-0 2xl:space-x-4 px-4">
        <div className="w-full gap-5 flex flex-col ">
    
          {/* <AccountSettings/> */}
          <SystemSettings/>
      
        </div>
      </div>
    </div>
  );
};

export default Settings;
