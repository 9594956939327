import React from "react";
import { useState } from "react";
import { FiUser } from "react-icons/fi";
import Swal from "sweetalert2";
import { NavLink,useNavigate } from "react-router-dom";
import { clearCookies,getObjFromCookie } from "../utils/Cookie";




const UserNavProfile = () => {
 const user = getObjFromCookie("userdetails")
//  console.log(user);

 const navigate = useNavigate();
 const [toggle, setToggle] = useState(false);
 


  const handlelogout = (e) => {
    e.preventDefault();
    clearCookies();
    Swal.fire("Logout Successful")
    navigate("/")

  };

  return (
    <div className="flex flex-col justify-center items-center p-2">
      <div>
        <button
          type="button"
          onClick={() => setToggle(!toggle)}
          className="flex text-sm bg-pink-500 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
        >
          <div className="flex justify-center items-center gap-1 lg:gap-4 lg:py-1 lg:px-4 px-1">
            <p className="text-white ">Hello, {user?.username}</p>
            <FiUser size={30} color="white" />
          </div>
        </button>
      </div>
      {toggle && (
        <div className="absolute right-4 top-16 z-50 my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600">
          <div className="mx-4 my-3" role="none">
            <p className="text-sm text-gray-900 dark:text-white">{user?.name}</p>
            <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">
              {user?.email}
            </p>
          </div>

          <div className="mt-1 py-1" role="none">
            <NavLink to="/vendor/Dashboard" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
              Dashboard
            </NavLink>
           

            <p onClick={handlelogout} className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white">
              Sign out
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserNavProfile;
