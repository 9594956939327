import React, { useState, useEffect } from "react";
import axios from "axios";
import isEqual from "lodash/isEqual";
import { getCookie } from "../../../utils/Cookie";
import { Button } from "@material-tailwind/react";

import Swal from "sweetalert2";

const SystemSettings = () => {
  const token = getCookie("_auth");
  // console.log("token", token);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const handleChange = (e) => {
    setUpdateInfo({ ...updateInfo, [e.target.name]: e.target.value });
  };

  let initialState = {
    api_key: null,
    api_secret: null,
    business_hours: null,
    business_registration_number: null,
    commission_for_riders: 0,
    commission_for_vendors: 0,
    facebook_link: null,
    instagram_link: null,
    language: "English",
    sms_api: null,
    smtp_password: null,
    smtp_port: null,
    smtp_server: null,
    smtp_username: null,
    system_name: "dessert to door",
    tax_id_number: null,
    twitter_link: null,
  };

  const [updateInfo, setUpdateInfo] = useState(initialState);
  const [info, setInfo] = useState({});
  useEffect(() => {
    // loading all Tickets
    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin/settings/`, config)
      .then((res) => {
        // console.log(res);

        if (info && !isEqual(res.data, info)) {
          setInfo(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [info]);

  const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      function filterNonNullValues(obj) {
        const newObj = {};

        for (const key in obj) {
          const value = obj[key];
          if (value !== null && value !== "") {
            newObj[key] = value;
          }
        }

        return newObj;
      }

      const data = filterNonNullValues(updateInfo);

      if (Object.keys(data).length === 0) {
        // No fields to update, exit early
        console.log("No data to update.");
        return;
      }
      console.log(data);
      const res = await axios.patch(
        `${process.env.REACT_APP_BASEURL}/admin/settings/`,
        data,
        config
      );

      if (res.status === 200) {
        // console.log("Update successful!");
        Swal.fire("Submitted!", "info Update!", "success");
      } else {
        console.log("Update failed.");
      }
    } catch (err) {
      console.error("Error updating data:", err);
      Swal.fire(
        "info",
        ` Failed to update ${JSON.stringify(err.res)}`,
        "error"
      );
    }
  };

  return (
    <div>
      {" "}
      <div className="flex-1 bg-white rounded-lg shadow-xl p-4">
        <div className="flex justify-between items-center w-full p-4">
          <h4 className="text-xl text-gray-900 font-bold">System Settings</h4>
        </div>
        <form onSubmit={handleUpdate}>
          <div className="w-full flex justify-start items-center flex-wrap gap-8 p-4">
            {/*  */}
            <div className="w-full grid lg:grid-cols-4 gap-8 md:grid-cols-2">
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  system_name
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info.system_name}
                  name="system_name"
                  onChange={handleChange}
                />
              </div>{" "}
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Business Hours
                </label>
                <input
                  type="number"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.business_hours}
                  name="business_hours"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Business Registration No.{" "}
                </label>
                <input
                  type="number"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.business_registration_number}
                  name="business_registration_number"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Tax ID No.{" "}
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.tax_id_number}
                  name="tax_id_number"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Riders Commissions{" "}
                </label>
                <input
                  type="number"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.commission_for_riders}
                  name="commission_for_riders"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Vendors Commissions{" "}
                </label>
                <input
                  type="number"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.commission_for_vendors}
                  name="commission_for_vendors"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Facebook Link
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.facebook_link}
                  name="facebook_link"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  Instagram Link
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.instagram_link}
                  name="instagram_link"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  twitter_link(X)
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.twitter_link}
                  name="twitter_link"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  API Key{" "}
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.api_key}
                  name="api_key"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  API Secret{" "}
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.api_secret}
                  name="api_secret"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  SMS API{" "}
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.sms_api}
                  name="sms_api"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  SMTP Password
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.smtp_password}
                  name="smtp_password"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  SMTP Port
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.smtp_port}
                  name="smtp_port"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  SMTP server
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.smtp_server}
                  name="smtp_server"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                  SMTP username{" "}
                </label>
                <input
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                  placeholder={info?.smtp_username}
                  name="smtp_username"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="flex justify-end items-center w-full p-4">
            <Button
              type="submit"
              className="p-4 bg-pink-500 text-white rounded-lg"
            >
              update
            </Button>
          </div>
        </form>
      </div>{" "}
    </div>
  );
};

export default SystemSettings;
