import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getCookie } from "../../../utils/Cookie";

function AddPromoModal({ openAdd, setOpenAdd }) {
  const token = getCookie("_auth");
  // console.log("token", token);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const initialstate = {
    code: "",
    compaign_name: "",
    number_of_times_used: "",
    percentage: "",
    expiry_date: "",
    date_created: "",
  };
  const [formData, setFormData] = useState(initialstate); // store the form data in an object

  // handle the submission of the form
  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();

    formdata.append("code", formData.code);
    formdata.append("compaign_name", formData.compaign_name);
    formdata.append("number_of_times_used", formData.number_of_times_used);
    formdata.append("percentage", formData.percentage);
    formdata.append("expiry_date", formData.expiry_date);
    formdata.append("date_created", formData.date_created);

    axios
      .post(`${process.env.REACT_APP_BASEURL}/admin/promo/`, formdata, config)
      .then((res) => {
        setOpenAdd(!openAdd);
        Swal.fire("Created", "Promo created sucessfully", "success");
      })
      .catch((err) => {
        // console.log(err);
        setOpenAdd(!openAdd);

        Swal.fire(
          "error",
          `Promo creation Failed ${err.response.data.detail}`,

          "error"
        );
      });
  };

  return (
    <>
      <div className=" flex flex-col justify-center items-center w-full h-fit p-4">
        <div className="flex flex-col justify-center items-center w-full ">
          <h1 className="text-xl font-semibold"> Add Promo.</h1>
        </div>
        <div className="flex justify-center items-start w-full p-4 lg:p-0 ">
          <div className="container">
            <form onSubmit={handleSubmit}>
              <>
                <div className="grid gap-4 md:grid-cols-2">
                  <div>
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      compaign_name
                    </label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="weekend bonus"
                      value={formData.compaign_name}
                      name="compaign_name"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      code
                    </label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="frindaySpecial"
                      name="code"
                      value={formData.code}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div className="">
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      number_of_times_used{" "}
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="number_of_times_used"
                      placeholder="9,7,12"
                      value={formData.number_of_times_used}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="">
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      percentage{" "}
                    </label>
                    <input
                      type="number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="percentage"
                      placeholder="9%,7%,12%"
                      value={formData.percentage}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="">
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      date_created{" "}
                    </label>
                    <input
                      type="datetime-local"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="date_created"
                      value={formData.date_created}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="">
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      expiry_date{" "}
                    </label>
                    <input
                      type="datetime-local"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="expiry_date"
                      value={formData.expiry_date}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="flex justify-end items-end  gap-8 pt-8">
                  <button
                    type="submit"
                    className="text-white bg-pink-500 py-2 px-8 rounded-full"
                  >
                    Submit
                  </button>
                </div>
              </>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddPromoModal;
