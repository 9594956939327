import React from "react";
import Chart from "react-apexcharts";
import Pie from "react-apexcharts";

const PieChart = ({ counts }) => {
  //   console.log(counts);
  const {
    total_featured_catelog_count,
    // total_offers_count,
    // total_order_count,
    // total_package_count,
    // total_product_count,
    total_promo_count,
    total_rider_count,
    total_ticket_count,
    total_user_count,
    total_vendor_count,
  } = counts;

  const state = {
    options: {
      chart: {
        type: "donut",
      },
      labels: [
        "Featured product",
        // "total Offers",
        // "Total Order",
        // "Total Package",
        // "Total Product",
        "Total Promos",
        "Total Rider",
        "Total Tickets",
        "Total User",
        "Total Vendor",
      ],
    },
    series: [
      total_featured_catelog_count,
      // total_offers_count,
      // total_order_count,
      // total_package_count,
      // total_product_count,
      total_promo_count,
      total_rider_count,
      total_ticket_count,
      total_user_count,
      total_vendor_count,
    ],
  };

  return (
    <div>
      {" "}
      <Chart options={state.options} series={state.series} type="pie" />
    </div>
  );
};

export default PieChart;
