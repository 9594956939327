import { RiCouponFill } from "react-icons/ri";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { HiOutlineCake } from "react-icons/hi";
import { TbTicketOff } from "react-icons/tb";
import { RiMotorbikeFill } from "react-icons/ri";
import React, { useState, useEffect } from "react";
import axios from "axios";
import MiniNav from "../../../Components/MiniNav";
import { getCookie, clearCookies } from "../../../utils/Cookie";
import BarChart from "./BarChart";
import PieChart from "./PieChart";
import { Spinner } from "@material-tailwind/react";
import isEqual from "lodash/isEqual";

const AdminDashboard = () => {
  const [counts, setCounts] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchCounts = () => {
    const token = getCookie("_auth");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin/admin_counts/`, config)
      .then((res) => {
        // console.log(res);
        if (counts && !isEqual(res.data, counts)) {
          setCounts(res.data);
        }
        setLoading(false);
        if (res.status === 401) {
          clearCookies();
          window.location = "/login";
        }
      })
      .catch((err) => {
        // console.log(err);
        fetchCounts();
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchCounts();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <MiniNav page={"Dashboard"} />
      <div className="my-4 flex gap-2 flex-col 2xl:flex-row space-y-4 2xl:space-y-0 2xl:space-x-4">
        <div className="flex-1 flex items-center gap-4 bg-white rounded-3xl shadow-xl px-8 py-4">
          <div className="text-6xl text-pink-600  font-bold">
            <RiMotorbikeFill />
          </div>
          <div>
            <h1 className="text-xl text-pink-600  font-bold">
              {loading ? <Spinner color="pink" /> : counts.total_rider_count}
            </h1>
            <p>Total Rider</p>
          </div>
        </div>

        <div className="flex-1 flex items-center gap-4 bg-white rounded-3xl shadow-xl px-8 py-4">
          <div className="text-6xl text-pink-600  font-bold">
            <TbTicketOff />
          </div>
          <div>
            {" "}
            <h1 className="text-xl text-pink-600  font-bold">
              {loading ? <Spinner color="pink" /> : counts.total_ticket_count}
            </h1>
            <p>Total Ticket</p>
          </div>
        </div>
        <div className="flex-1 flex items-center gap-4 bg-white rounded-3xl shadow-xl px-8 py-4">
          <div className="text-6xl text-pink-600  font-bold">
            <HiOutlineCake />
          </div>
          <div>
            {" "}
            <h1 className="text-xl text-pink-600  font-bold">
              {loading ? (
                <Spinner color="pink" />
              ) : (
                counts.total_featured_catelog_count
              )}
            </h1>
            <p>Total featured product</p>
          </div>
        </div>
      </div>
      <div className="my-4 flex flex-col 2xl:flex-row space-y-4 2xl:space-y-0 2xl:space-x-4">
        <div className="flex-1 flex items-center gap-4 bg-white rounded-3xl shadow-xl px-8 py-4">
          <div className="text-6xl text-pink-600  font-bold">
            <FaUsers />
          </div>
          <div>
            {" "}
            <h1 className="text-xl text-pink-600  font-bold">
              {loading ? <Spinner color="pink" /> : counts.total_vendor_count}
            </h1>
            <p>Total Vendor</p>{" "}
          </div>
        </div>
        <div className="flex-1 flex items-center gap-4 bg-white rounded-3xl shadow-xl px-8 py-4">
          <div className="text-6xl text-pink-600  font-bold">
            <AiOutlineUsergroupAdd />
          </div>
          <div>
            {" "}
            <h1 className="text-xl text-pink-600  font-bold">
              {loading ? <Spinner color="pink" /> : counts.total_user_count}
            </h1>
            <p>Total User</p>{" "}
          </div>
        </div>
        <div className="flex-1 flex items-center gap-4 bg-white rounded-3xl shadow-xl px-8 py-4">
          <div className="text-6xl text-pink-600  font-bold">
            <RiCouponFill />
          </div>
          <div>
            {" "}
            <h1 className="text-xl text-pink-600  font-bold">
              {loading ? <Spinner color="pink" /> : counts.total_promo_count}
            </h1>
            <p>Total promos</p>
          </div>
        </div>
      </div>
      <div className="my-4 flex flex-col  2xl:flex-row space-y-4 2xl:space-y-0 2xl:space-x-4 w-full">
        <div className="flex-1 bg-white rounded-lg shadow-xl px-8">
          {loading ? <Spinner color="pink" /> : <BarChart counts={counts} />}
        </div>
        <div className="flex-1 bg-white rounded-lg shadow-xl px-8">
          {loading ? <Spinner color="pink" /> : <PieChart counts={counts} />}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
