export const getCookie = (name) => {
  const cookieValue = document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`);
  return cookieValue ? cookieValue.pop() : "";
};

export const saveToCookie = (key, value, expirationDays) => {
  const expirationDate = new Date();
  expirationDate.setDate(expirationDate.getDate() + expirationDays);
  const cookieValue =
    encodeURIComponent(JSON.stringify(value)) +
    "; expires=" +
    expirationDate.toUTCString();
  document.cookie = key + "=" + cookieValue;
};

export const getObjFromCookie = (key) => {
  const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(key + "="));

  if (cookie) {
    const cookieValue = decodeURIComponent(cookie.split("=")[1]);
    return JSON.parse(cookieValue);
  }

  return null;
};

export const clearCookies = () => {
  const cookies = document.cookie.split(";");

  // Loop through all cookies and set their expiration date to the past
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
};
