import React, { useState, useEffect } from "react";
import axios from "axios";
import { getCookie } from "../../../utils/Cookie";

// import MiniNav from "../../../Components/MiniNav";

const ViewStaffModal = ({ ID }) => {
  const token = getCookie("_auth");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const [Staff, setStaff] = useState({});
  console.log(Staff);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin/staff/${ID}`, config)
      .then((res) => {
        console.log(res.data);
        setStaff(res.data);
      })
      .catch((err) => console.log(err));
  });
  return (
    <div>
      {/* <MiniNav page={"Account Information"} /> */}
      <div className=" h-full bg-gray-200 p-4">
        <div className="bg-white rounded-lg shadow-xl pb-4">
          <div className="min w-full h-[250px] lg:h-[320px]">
            {/* <img
              src={`${process.env.REACT_APP_BASEURL}` + Staff.cover_photo}
              className="w-full h-full rounded-tl-lg rounded-tr-lg"
            /> */}
          </div>
          <div className="flex flex-col items-center -mt-20">
            <img
              src={`${process.env.REACT_APP_BASEURL}`+Staff.cover_photo}
              className="w-40 border-4 border-white rounded-full"
              alt="cover_photo"
            />
            <div className="flex items-center space-x-2 mt-2">
              <p className="text-2xl">{Staff.user?.fullname}</p>
            
            </div>
            <div className="flex border-y py-2">
              <span className="">Staff ID </span>
              <span className="text-gray-700">{Staff.id}</span>
            </div>

            <p className="text-sm text-gray-500">{Staff.user?.address}.</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col 2xl:flex-row space-y-4 2xl:space-y-0 2xl:space-x-4 px-4">
        <div className="w-full flex flex-col">
          <div className="flex-1 bg-white rounded-lg shadow-xl p-8">
            <div className="w-full flex justify-start items-center flex-wrap gap-8 p-4">
              <ul className="mt-2 text-gray-700">
                <li className="flex border-b py-2">
                  <span className="font-bold w-24">username:</span>
                  <span className="text-gray-700">{Staff.user?.username}</span>
                </li>
                <li className="flex border-b py-2">
                  <span className="font-bold w-24">Full name:</span>
                  <span className="text-gray-700">{Staff.user?.fullname}</span>
                </li>
              </ul>
              <ul className="mt-2 text-gray-700">
                <li className="flex border-b py-2">
                  <span className="font-bold w-24">Mobile:</span>
                  <span className="text-gray-700">
                    {Staff.user?.phone_number}
                  </span>
                </li>
                <li className="flex border-b py-2">
                  <span className="font-bold w-24">Email:</span>
                  <span className="text-gray-700">{Staff.user?.email}</span>
                </li>
                
              </ul>
            </div>
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

export default ViewStaffModal;
