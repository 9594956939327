import React, { useEffect, useState } from "react";
import { RiUserSharedLine } from "react-icons/ri";
import { MdSettings } from "react-icons/md";
import axios from "axios";
import { getCookie, clearCookies } from "../../../utils/Cookie";
import { NavLink, useLocation } from "react-router-dom";
import { FaChartPie, FaUsers, FaBars, FaMotorcycle } from "react-icons/fa";
import { RiCoupon5Line } from "react-icons/ri";

import { BsPersonWorkspace } from "react-icons/bs";
import { TbTicketOff } from "react-icons/tb";
import { BiSolidWallet, BiSolidPackage } from "react-icons/bi";
import { MdShoppingBag, MdOutlineLocalOffer } from "react-icons/md";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { IconButton } from "@material-tailwind/react";

const SidebarNav = () => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const isMenuActive = (menuPath) => {
    return location.pathname === menuPath;
  };
  const style = {
    backgroundColor: "#f472b6",
  };

  const [counts, setCounts] = useState({});
  // console.log(counts);
  const fetchCounts = () => {
    const token = getCookie("_auth");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin/admin_counts/`, config)
      .then((res) => {
        // console.log(res);
        setCounts(res.data);
        if (res.status === 401) {
          clearCookies();
          window.location = "/login";
        }
      })
      .catch((err) => {
        // console.log(err);
        fetchCounts();
      });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      fetchCounts();
    }, 10000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      {/* Sidebar */}

      <Sidebar collapsed={collapsed} style={{ height: "100vh" }}>
        <Menu iconShape="square">
          <MenuItem
            className="py-4"
            icon={<FaBars />}
            onClick={() => {
              setCollapsed(!collapsed);
            }}
          >
            {" "}
            <h2 className="text-center font-bold">Admin</h2>
          </MenuItem>

          <MenuItem
            icon={<FaChartPie />}
            component={<NavLink to="/admin/dashboard/" />}
            className="hover:bg-pink-400 mb-2 "
            style={isMenuActive("/admin/dashboard/") ? style : null}
          >
            Dashboard
          </MenuItem>

          <MenuItem
            icon={<MdShoppingBag />}
            component={<NavLink to="/admin/products/" />}
            className="hover:bg-pink-400 mb-2 "
            style={isMenuActive("/admin/products/") ? style : null}
          >
            Products
          </MenuItem>
          <MenuItem
            icon={<BiSolidPackage />}
            component={<NavLink to="/admin/hampers/" />}
            className="hover:bg-pink-400 mb-2 "
            style={isMenuActive("/admin/hampers/") ? style : null}
          >
            Hampers
          </MenuItem>

          <MenuItem
            icon={<MdOutlineLocalOffer />}
            component={<NavLink to="/admin/offers/" />}
            className="hover:bg-pink-400 mb-2 "
            style={isMenuActive("/admin/offers/") ? style : null}
          >
            Offers
          </MenuItem>
          {/* <MenuItem
            icon={<RiCoupon5Line />}
            component={<NavLink to="/admin/promo/" />}
            className="hover:bg-pink-400 mb-2 "
            style={isMenuActive("/admin/promo/") ? style : null}
          >
            Promo
          </MenuItem> */}
   
          {/* vendors */}
          <MenuItem
            icon={<FaUsers />}
            component={<NavLink to="/admin/vendors/" />}
            className="hover:bg-pink-400 mb-2 "
            style={isMenuActive("/admin/vendors/") ? style : null}
          >
            vendors
            <IconButton className="rounded-full bg-pink-500">
              {counts.total_pending_vendor_count}
            </IconButton>
          </MenuItem>

          {/* Riders */}
          <MenuItem
            icon={<FaMotorcycle />}
            component={<NavLink to="/admin/riders/" />}
            className="hover:bg-pink-400 mb-2 "
            style={isMenuActive("/admin/riders/") ? style : null}
          >
            Riders{" "}
            <IconButton className="rounded-full bg-pink-500">
              {counts.total_pending_rider_count}
            </IconButton>
          </MenuItem>

          {/* <MenuItem
            icon={<BiSolidWallet />}
            component={<NavLink to="/admin/transactions/" />}
            className="hover:bg-pink-400 mb-2 "
            style={isMenuActive("/admin/transactions/") ? style : null}
          >
            Transactions
          </MenuItem> */}
          <MenuItem
            icon={<RiUserSharedLine />}
            component={<NavLink to="/admin/users/" />}
            className="hover:bg-pink-400 mb-2 "
            style={isMenuActive("/admin/users/") ? style : null}
          >
            Users
          </MenuItem>
          <MenuItem
            icon={<TbTicketOff />}
            component={<NavLink to="/admin/tickets/" />}
            className="hover:bg-pink-400 mb-2 "
            style={isMenuActive("/admin/tickets/") ? style : null}
          >
            Tickets
          </MenuItem>
   
        </Menu>
      </Sidebar>

      {/* Sidebar */}
    </>
  );
};

export default SidebarNav;
