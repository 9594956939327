import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getCookie } from "../../../utils/Cookie";
import { AiFillDelete } from "react-icons/ai";

import {
  Avatar,
  Button,
  Dialog,
  Input,
  Spinner,
} from "@material-tailwind/react";
import MiniNav from "../../../Components/MiniNav";

const PackageCategories = () => {
  const token = getCookie("_auth");

  const config = {
    headers: {
      "Content-Type": "application/formData",
      Authorization: `Bearer ${token}`,
    },
  };

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);

  const [openAdd, setOpenAdd] = useState(false);

  const [packageCategories, setPackageCategorys] = useState([]);
  const [image, setImage] = useState(null);
  const [name, setName] = useState(null);

  const [loading, setLoading] = useState(true);

  const deletePackageCategory = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASEURL}/shop/package-category/${id}`,
        config
      )
      .then(() => {
        Swal.fire("Submitted!", "hamper category removed!", "success");
      })
      .catch((err) => {
        Swal.fire(
          "hamper category",
          ` Failed to delete ${JSON.stringify(err.response.data)}`,
          "error"
        );

        console.log("error", err.response.data);
      });
  };
  useEffect(() => {
    // loading the vendor's Package categorys

    axios
      .get(
        `${process.env.REACT_APP_BASEURL}/shop/package-category/?page=${page}`,
        config
      )
      .then((res) => {
        setPackageCategorys(res.data);
        setCount(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPage(1);
      });
  }, [packageCategories, page]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("image", image);
    // console.log(formData);
    // console.log(image);

    axios
      .post(
        `${process.env.REACT_APP_BASEURL}/shop/package-category/`,
        formdata,
        config
      )
      .then(() => {
        Swal.fire("Submitted!", "hamper category created!", "success");
        setOpenAdd(!openAdd);
      })
      .catch((err) => {
        setOpenAdd(!openAdd);

        Swal.fire(
          "hamper",
          `hamper category creation Failed ${JSON.stringify(
            err.response.data
          )}`,
          "error"
        );
        console.log("error", err.response.data);
      });
  };

  return (
    <>
      <MiniNav page={"hamper categories"} />
      <div className="px-4">
        <div className="flex justify-start items-center gap-5 w-full">
          <div className="flex justify-end items-center w-full">
            <Button
              className="px-4 py-2 bg-pink-500 text-white rounded-lg"
              onClick={() => setOpenAdd(true)}
            >
              Add Package category
            </Button>
          </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #ID
                </th>
                <th scope="col" className="px-6 py-3">
                  IMG
                </th>
                <th scope="col" className="px-6 py-3">
                  Package category NAME
                </th>

                <th scope="col" className="px-6 py-3">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? ""
                : packageCategories.length === 0
                ? "No Package categorys"
                : packageCategories.map((PackageCategory) => (
                    <tr
                      key={PackageCategory.id}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 ">#{PackageCategory.id}</td>
                      <td className="px-6 py-4">
                        {" "}
                        <Avatar
                          size="lg"
                          src={
                            process.env.REACT_APP_BASEURL +
                            PackageCategory?.image
                          }
                          alt={PackageCategory.name}
                          className="text-center mx-autPackageCategoryo"
                        />
                      </td>
                      <td className="px-6 py-4"> {PackageCategory.name}</td>

                      <td className="px-6 py-4">
                        <div className="flex items-center gap-3">
                          <button
                            className="font-medium text-red-600 dark:text-red-500 hover:underline"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deletePackageCategory(PackageCategory.id);
                                }
                              });
                            }}
                          >
                            <AiFillDelete size={30} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {loading && (
            <div className="h-40">
              <div className="w-full flex justify-center items-center h-full">
                <Spinner color="pink" className="h-12 w-12" />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end items-center gap-5 p-5 mb-4">
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page - 1)}
          >
            prev
          </button>
          <div>{page}</div>
          <div>/</div>
          <div>{Math.ceil(count / 10)}</div>
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page + 1)}
          >
            next
          </button>
        </div>
      </div>
      <Dialog open={openAdd} handler={() => setOpenAdd(!openAdd)}>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col justify-center gap-4 p-8">
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Category Name:
              </label>
              <input
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
              />
            </div>{" "}
            <div>
              <h1>Cover Photo</h1>
              <Input
                type="file"
                required
                name="image"
                accept="image/jpeg, image/png image/jpg image/gif"
                onChange={(e) => setImage(e.target.files[0])}
              />
            </div>
            <div>
              <Button
                type="submit"
                className="px-4 py-2 bg-pink-500 text-white rounded-lg"
              >
                Add Category
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};

export default PackageCategories;
