import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getCookie } from "../../../utils/Cookie";
import { Spinner } from "@material-tailwind/react";

const ViewProductModal = ({ Id, openView, setOpenView }) => {
  const token = getCookie("_auth");
  const product = Id;

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const [productFeatured, setProductFeatured] = useState({
    products: [product.Id],
  });

  // const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(false);
  //   console.log(product);

  const addFeatured = (e) => {
    e.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/featured-catalog/`,
        productFeatured,
        config
      )
      .then(() => {
        setLoading(false);
        setOpenView(!openView);
        Swal.fire("Submitted!", "Featured Product created!", "success");
      })
      .catch((err) => {
        setOpenView(!openView);
        Swal.fire(
          "Product",
          `Featured Product creation Failed ${JSON.stringify(
            err.response.data
          )}`,
          "error"
        );
        console.log("error", err.response.data);
      });
  };

  // useEffect(() => {
  //   // loading the products
  //   axios
  //     .get(`${process.env.REACT_APP_BASEURL}/admin/product/${Id}`, config)
  //     .then((res) => {
  //       // console.log(res.data);
  //       setProduct(res.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, [Id]);

  return (
    <>
      {loading ? (
        <div className="w-full flex justify-center items-center h-full">
          <Spinner color="pink" className="h-12 w-12" />
        </div>
      ) : (
        <>
          <div className="flex justify-start items-start bg-white border border-gray-200 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700">
            <div className="w-1/2 rounded-xl">
              <img
                className="rounded-t-lg w-full h-full"
                // src={process.env.REACT_APP_BASEURL + product?.image}
                src={product?.image}
                alt="dessert"
              />
            </div>
            <div className="flex flex-col gap-4 items-start justify-start p-5">
              <div className="flex gap-4 justify-center items-center">
                <h1>Name</h1>

                <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                  {product?.name}
                </p>
              </div>
              <div className="flex gap-4 justify-center items-center">
                <h1>description</h1>

                <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                  {product?.description}
                </p>
              </div>
              <div className="flex gap-4 justify-center items-center">
                <h1>product_size</h1>

                <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                  {product?.product_size}
                </p>
              </div>
              <div className="flex gap-4 justify-center items-center">
                <h1>price</h1>

                <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                  {product?.price}
                </p>
              </div>
              <div className="flex gap-4 justify-center items-center">
                <h1>in stock</h1>

                <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                  {product?.in_stock}
                </p>
              </div>
              <div className="flex gap-4 justify-center items-center">
                <h1>shop</h1>

                <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                  {product?.shop}
                </p>
              </div>
              <div>
                <button
                  className="px-4 py-2 bg-pink-500 text-white rounded-lg"
                  onClick={addFeatured}
                >
                  Add Featured Product
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ViewProductModal;
