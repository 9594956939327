import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Stepper, Step } from "react-form-stepper";
import vendorImg from "../../../Assets/vendorSignup.png";
import MapContainer from "../../../Components/MapContainer";

function AddVendorModal({ openAdd, setOpenAdd }) {
  const [activeStep, setActiveStep] = useState(0); // keep track of the current step
  // handle the change of input values and update the formData object
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // handle the click of the next button and increment the activeStep
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  // handle the click of the previous button and decrement the activeStep
  const handlePrev = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const [confirmPassword, setConfirmPassword] = useState("");

  const initialstate = {
    fullname: "",
    email: "",
    phone_number: "",
    gps_address: "",
    gender: "",
    dob: "",
    shop_name: "",
    registration: "",
    address: "",
    managers_number: 0,
    username: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialstate); // store the form data in an object
  const [mapState, setMapState] = useState({
    lat: 5.558322433011036,
    lng: -0.19707847619629293,
  });

  const [coverPhoto, setCoverPhoto] = useState(null);
  const [companyLogo, setcompanyLogo] = useState(null);
  const [companyRegistration, setcompanyRegistration] = useState(null);
  const [ghanaCard, setGhanaCard] = useState(null);

  // handle the submission of the form
  const handleSubmit = (e) => {
    e.preventDefault();

    const formdata = new FormData();

    formdata.append("ghanaCard", ghanaCard);
    formdata.append("cover_photo", coverPhoto);
    formdata.append("company_registration", companyRegistration);
    formdata.append("company_logo", companyLogo);

    formdata.append("latitude", `${mapState?.lat}`);
    formdata.append("longitude", `${mapState?.lng}`);

    formdata.append("fullname", formData.fullname);
    formdata.append("email", formData.email);
    formdata.append("phone_number", formData.phone_number);
    formdata.append("gps_address", formData.gps_address);
    formdata.append("gender", formData.gender);
    formdata.append("dob", formData.dob);
    formdata.append("shop_name", formData.shop_name);
    formdata.append("registration", formData.registration);
    formdata.append("address", formData.address);
    formdata.append("managers_number", formData.managers_number);
    formdata.append("username", formData.username);
    formdata.append("password", formData.password);

    // console.log(
    //   "files",
    //   coverPhoto,
    //   companyLogo,
    //   companyRegistration,
    //   ghanaCard
    // );

    axios
      .post(`${process.env.REACT_APP_BASEURL}/vender-register/`, formdata)
      .then((response) => {
        setOpenAdd(!openAdd);
        Swal.fire("Created", "Vendor created sucessfully", "success");
      })
      .catch((err) => {
        setOpenAdd(!openAdd);
        Swal.fire(
          "error",
          `Vendor creation Failed ${err.response.data.detail}`,

          "error"
        );
      });
  };

  return (
    <>
      <div className=" flex flex-col justify-center items-center w-full h-fit p-4">
        <div className="flex flex-col justify-center items-center w-full ">
          {/* <img src={vendorImg} alt="vendor" className="w-full lg:max-h-64" /> */}
          <h1 className="text-xl font-semibold"> Register as a vendor.</h1>
          <p className="text-base">
            Welcome to Dessert to door vendor's registration page.
          </p>
        </div>
        <div className="flex justify-center items-start w-full p-4 lg:p-0 ">
          <div className="container">
            <Stepper activeStep={activeStep}>
              <Step label="Personal Details" className="font-bold" />
              <Step label="Shop Details" className="font-bold" />
              <Step label="Shop Location" className="font-bold" />
              <Step label="Supporting Documents" className="font-bold" />
            </Stepper>
            <form onSubmit={handleSubmit}>
              {activeStep === 0 && (
                <>
                  <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        FullName
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="John"
                        value={formData.fullname}
                        name="fullname"
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        UserName
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="John93"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="243567876"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Gender
                      </label>
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                      >
                        <option value="">Choose...</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder=""
                        name="dob"
                        value={formData.dob}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-6">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        name="email"
                        placeholder="john.doe@gmail.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-6">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Password
                      </label>
                      <input
                        type="password"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="•••••••••"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-6">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Confirm password
                      </label>
                      <input
                        type="password"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="•••••••••"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        required
                      />
                      {formData.password === confirmPassword ? (
                        ""
                      ) : (
                        <p className="text-red-600">Password must match..</p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end items-end mt-4 pt-16">
                    <button
                      onClick={handleNext}
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Shop Name
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Sweeet's"
                        name="shop_name"
                        value={formData.shop_name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Shop Registration No.
                      </label>
                      <input
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="2345678"
                        name="registration"
                        value={formData.registration}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Manager's Number.
                      </label>
                      <input
                        type="tel"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="2345678"
                        name="managers_number"
                        value={formData.managers_number}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        GPS Location
                      </label>
                      <input
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="2345678"
                        name="gps_address"
                        value={formData.gps_address}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  {/* Location input */}
                  {/* <div className="p-4">
                    <h1 className="text-xl text-center p-2">
                      Select the Shop location on the map.
                    </h1>
                    <MapContainer
                      selected={mapState}
                      setSelected={setMapState}
                    />
                  </div> */}
                  {/* Location input */}
                  <div className="flex justify-end items-end gap-8">
                    <button
                      onClick={handlePrev}
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Prev
                    </button>

                    <button
                      onClick={handleNext}
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
              {activeStep === 2 && (
                <>
                  {/* Location input */}
                  <div className="p-4">
                    <h1 className="text-xl text-center p-2">
                      Select the Shop location on the map.
                    </h1>
                    <MapContainer
                      selected={mapState}
                      setSelected={setMapState}
                    />
                  </div>
                  {/* Location input */}
                  <div className="flex justify-end items-end gap-8">
                    <button
                      onClick={handlePrev}
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Prev
                    </button>

                    <button
                      onClick={handleNext}
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
              {activeStep === 3 && (
                <>
                  <div>
                    <label className="flex justify-center items-center gap-4 w-full text-center mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      <h1> Shop Photo </h1>{" "}
                      <p className="text-xs">what users see:</p>
                    </label>
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      type="file"
                      onChange={(e) => setCoverPhoto(e.target.files[0])}
                      required
                    />

                    <label className="flex justify-center items-center gap-4 w-full text-center mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      <h1> Owner's Ghana-card</h1>{" "}
                      <p className="text-xs">Optional</p>
                    </label>
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      type="file"
                      onChange={(e) => setGhanaCard(e.target.files[0])}
                      // required
                    />

                    <label className="flex justify-center items-center gap-4 w-full text-center mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      <h1>Company Certificate</h1>{" "}
                      <p className="text-xs">Optional</p>
                    </label>
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      type="file"
                      // required
                      onChange={(e) =>
                        setcompanyRegistration(e.target.files[0])
                      }
                    />
                    <label className="flex justify-center items-center gap-4 w-full text-center mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Company Logo
                    </label>
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      type="file"
                      onChange={(e) => setcompanyLogo(e.target.files[0])}
                      required
                    />
                  </div>
                  <div className="flex justify-end items-end  gap-8 pt-8">
                    <button
                      onClick={handlePrev}
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Prev
                    </button>
                    <button
                      type="submit"
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddVendorModal;
