import React, { useState } from "react";

import axios from "axios";
import Swal from "sweetalert2";
import { Spinner } from "@material-tailwind/react";

import { getCookie } from "../../../utils/Cookie";

const Sms = () => {
  const token = getCookie("_auth");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const initialstate = {
    user_type: "",
    message: "",
  };
  const [formData, setFormData] = useState(initialstate);
  const [loading, setLoading] = useState(false);

  console.log(formData);

  // handle the submission of the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
  
    const formdata = new FormData();
    formdata.append("user_type", formData.user_type);
    formdata.append("message", formData.message);

    axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/bulk_sms/`,
        formdata,
        config
      )
      .then((res) => {
        setLoading(false);
        console.log(res);
        Swal.fire("sucessfully", "sms sent ", "success");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        Swal.fire("error", `sms Failed ${err.response.data.detail}`, "error");
      });
  };
  return (
    <div>
      {loading && (
        <div className="w-full flex justify-center items-center ">
          <div className="flex justify-center items-center gap-4 w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800">
            <Spinner color="pink" />
            <div className="text-sm font-normal">
              {" "}
              Please wait while Sending SMS
            </div>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col w-full gap-8">
          <div>
            <h1>Receiver's</h1>
            <select
              className=" bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-fu p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
              type="select"
              name="user_type"
              onChange={handleChange}
            >
              <option value="">Select</option>
              <option value="user">Users</option>
              <option value="vendor">Vendors</option>
              <option value="rider">Riders</option>
            </select>
          </div>

          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Your message
            </label>
            <textarea
              name="message"
              className="min-h-[400px] block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-pink-500 focus:border-pink-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
              placeholder="Write your message here..."
              onChange={handleChange}
            ></textarea>
          </div>
          <div>
            <button
              type="submit"
              className="flex gap-3 items-end justify-center px-8 py-4 text-xl bg-pink-500 text-white rounded-lg disabled:bg-gray-400"
              disabled={loading}
            >
              Send
              {loading && <Spinner color="pink" />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Sms;
