import { BiUserCircle } from "react-icons/bi";
import { PhotoProvider, PhotoView } from "react-photo-view";
import { Image } from "react-image";

import React from "react";

const ViewRiderModal = ({ ID }) => {
  const Rider = ID;
  console.log({ Rider });
  return (
    <div className="flex flex-col gap-4 bg-gray-200 p-4">
      {/* <div> */}
      <div className="flex flex-col justify items-center mt-2 bg-white rounded-lg shadow-xl pb-4">
        <BiUserCircle size={150} />
        {Rider?.status === "Approved" ? (
          <span className="bg-green-600 rounded-full p-1 text-white">
            Approved
          </span>
        ) : (
          <span className="bg-red-600 rounded-full p-1 text-white">
            pending
          </span>
        )}
      </div>
      {/* </div> */}
      <div className=" bg-white rounded-lg shadow-xl p-8 grid grid-cols-1 lg:grid-cols-3 gap-4 place-content-center">
        <div className="border-b text-gray-700">
          <span className="font-bold pr-2">username:</span>
          {Rider.user?.username}
        </div>
        <div className="border-b text-gray-700">
          <span className="font-bold  pr-2">Full name:</span>
          {Rider?.user?.fullname ||
            Rider?.user?.first_name + Rider?.user?.first_last}
        </div>
        <div className="border-b text-gray-700">
          <span className="font-bold pr-2">Mobile:</span>
          {Rider?.user?.phone_number}
        </div>
        <div className="border-b text-gray-700">
          <span className="font-bold pr-2">Email:</span>
          {Rider.user?.email}{" "}
        </div>
        <div className="border-b text-gray-700">
          <span className="font-bold pr-2">Gender:</span>
          {Rider.user?.gender}{" "}
        </div>
        <div className="border-b text-gray-700">
          <span className="font-bold pr-2">Adress:</span>
          {Rider?.residential_address}{" "}
        </div>
        <div className="border-b text-gray-700">
          <span className="font-bold pr-2">Rider Id:</span>
          {Rider?.id}{" "}
        </div>
        <div className="border-b text-gray-700">
          <span className="font-bold pr-2">Date Joined :</span>
          {new Date(Rider?.user.date_joined).toLocaleString}{" "}
        </div>
        <div className="border-b text-gray-700">
          <span className="font-bold pr-2">Dob :</span>
          {Rider?.user.dob}{" "}
        </div>
      </div>
      <div className=" bg-white rounded-lg shadow-xl p-8 grid grid-cols-1 lg:grid-cols-3 gap-4 place-content-center">
        <div>
          License
          {/* <Image src={Rider.lincense} alt="" /> */}
          {/* <PhotoProvider>
            <div className="foo">
              <PhotoView src={Rider.lincense}>
                <Image  src={Rider.lincense} alt="" />
              </PhotoView>
            </div>
          </PhotoProvider> */}
        </div>
        <div>Ghana Card</div>
        <div>Vehicle Picture</div>
      </div>
    </div>
  );
};

export default ViewRiderModal;
