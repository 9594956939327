import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Stepper, Step } from "react-form-stepper";
// import RiderImg from "../Assets/riderSignup.png";

function AddRiderModal({ openAdd, setOpenAdd }) {
  const [activeStep, setActiveStep] = useState(0); // keep track of the current step
  // handle the change of input values and update the formData object
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  // handle the click of the next button and increment the activeStep
  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };
  // handle the click of the previous button and decrement the activeStep
  const handlePrev = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const [confirmPassword, setConfirmPassword] = useState("");

  const initialstate = {
    fullname: "",
    email: "",
    phone_number: "",
    residential_address: "",
    gender: "",
    dob: "",
    vehicle_registration_number: "",
    lincense: "",
    address: "",
    emergency_contact: 0,
    number_of_employees: 0,
    description: "",
    profile: "",
    username: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialstate); // store the form data in an object

  const [photoOfVehicle, setPhotoOfVehicle] = useState(null);
  const [ghanaCard, setGhanaCard] = useState(null);
  const [companylincense, setcompanylincense] = useState(null);

  // handle the submission of the form
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);

    const formdata = new FormData();
    formdata.append("fullname", formData.fullname);
    formdata.append("email", formData.email);
    formdata.append("phone_number", formData.phone_number);
    formdata.append("residential_address", formData.residential_address);
    formdata.append("gender", formData.gender);
    formdata.append("dob", formData.dob);
    formdata.append(
      "vehicle_registration_number	",
      formData.vehicle_registration_number
    );
    formdata.append("address", formData.address);
    formdata.append("emergency_contact", formData.emergency_contact);
    formdata.append("description", formData.description);
    formdata.append("username", formData.username);
    formdata.append("password", formData.password);
    formdata.append("lincense", formData.lincense);
    formdata.append("ghanaCard", ghanaCard);
    formdata.append("lincense", companylincense);
    formdata.append("photo_of_vehicle", photoOfVehicle);

    // console.log("files", photoOfVehicle, ghanaCard, companylincense);
    console.log("formData", formData);

    // you can send the formData to an API
    axios
      .post(`${process.env.REACT_APP_BASEURL}/rider-register/`, formdata)
      .then(() => {
        setOpenAdd(!openAdd);

        Swal.fire("Created", "Rider created sucessfully", "success");
      })
      .catch((err) => {
        // console.log(err);
        setOpenAdd(!openAdd);
        Swal.fire(
          "error",
          `Rider creation Failed ${err.response.data.detail}`,
          "error"
        );
      });
  };

  return (
    <>
      {" "}
      <div className="flex flex-col justify-center items-center w-full h-fit p-4 ">
        <div className="flex flex-col justify-center items-center w-full ">
          {/* <img src={RiderImg} alt="rider" className="w-full lg:max-h-64" /> */}
          <h1 className="text-xl font-semibold"> Register as a Rider.</h1>
          <p className="text-base">
            Welcome to Dessert to door Rider's registration page.
          </p>
        </div>
        <div className="flex justify-center items-start w-full  p-4  ">
          <div className="container">
            <Stepper activeStep={activeStep}>
              <Step label="Personal Details" />
              <Step label="Shop Details" />
              <Step label="Supporting Documents" />
            </Stepper>
            <form onSubmit={handleSubmit}>
              {activeStep === 0 && (
                <>
                  <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        FullName
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="John"
                        value={formData.fullname}
                        name="fullname"
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        UserName
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="John93"
                        name="username"
                        value={formData.username}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="243567876"
                        name="phone_number"
                        value={formData.phone_number}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Gender
                      </label>
                      <select
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        name="gender"
                        value={formData.gender}
                        onChange={handleChange}
                      >
                        <option value="">Choose...</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </select>
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Date of Birth
                      </label>
                      <input
                        type="date"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder=""
                        name="dob"
                        value={formData.dob}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        name="email"
                        placeholder="john.doe@gmail.com"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Residential Address
                      </label>
                      <input
                        type="email"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        name="residential_address"
                        placeholder="eg. street.."
                        value={formData.residential_address}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Password
                      </label>
                      <input
                        type="password"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="•••••••••"
                        name="password"
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Confirm password
                      </label>
                      <input
                        type="password"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="•••••••••"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                      />
                      {formData.password === confirmPassword ? (
                        ""
                      ) : (
                        <p className="text-red-600">Password must match..</p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-end items-end mt-4 pt-16">
                    <button
                      onClick={handleNext}
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
              {activeStep === 1 && (
                <>
                  <div className="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        lincense Details
                      </label>
                      <input
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Sweeet's"
                        name="lincense"
                        value={formData.lincense}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div>
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        vehicle Registration No.
                      </label>
                      <input
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="2345678"
                        name="vehicle_registration_number"
                        value={formData.vehicle_registration_number}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="flex justify-end items-end gap-8">
                    <button
                      onClick={handlePrev}
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Prev
                    </button>

                    <button
                      onClick={handleNext}
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
              {activeStep === 2 && (
                <>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Lincense
                    </label>
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      type="file"
                      required
                      onChange={(e) => setcompanylincense(e.target.files[0])}
                    />

                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Ghana-card
                    </label>
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      type="file"
                      onChange={(e) => setGhanaCard(e.target.files[0])}
                      required
                    />

                    <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                      Pictue of Vehicle
                    </label>
                    <input
                      className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                      type="file"
                      onChange={(e) => setPhotoOfVehicle(e.target.files[0])}
                      required
                    />
                  </div>
                  <div className="flex justify-end items-end  gap-8 pt-8">
                    <button
                      onClick={handlePrev}
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Prev
                    </button>
                    <button
                      type="submit"
                      className="text-white bg-pink-500 py-2 px-8 rounded-full"
                    >
                      Submit
                    </button>
                  </div>
                </>
              )}
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddRiderModal;
