import React from "react";
import Chart from "react-apexcharts";

const BarChart = ({ counts }) => {
  //   console.log(counts);
  const {
    total_featured_catelog_count,
    // total_offers_count,
    // total_order_count,
    // total_package_count,
    // total_product_count,
    total_promo_count,
    total_rider_count,
    total_ticket_count,
    total_user_count,
    total_vendor_count,
  } = counts;

  const state = {
    options: {
      chart: {
        id: "basic-bar",
      },
      xaxis: {
        categories: [
          "Featured product",
          // "total Offers",
          // "Total Order",
          // "Total Package",
          // "Total Product",
          "Total Promos",
          "Total Rider",
          "Total Tickets",
          "Total User",
          "Total Vendor",
        ],
      },
      plotOptions: {
        bar: {
          colors: {
            ranges: [
              {
                from: 0,
                to: 50,
                color: "#eb65a6", // Custom color for the bar when the value is between 0 and 50
              },
              {
                from: 51,
                to: 100,
                color: "#eb65a6", // Custom color for the bar when the value is between 51 and 100
              },
              {
                from: 101,
                to: 150,
                color: "#eb65a6", // Custom color for the bar when the value is between 101 and 150
              },
              {
                from: 151,
                to: 200,
                color: "#eb65a6", // Custom color for the bar when the value is between 151 and 200
              },
              // Add more ranges and colors as needed
            ],
          },
        },
      },
    },
    series: [
      {
        name: "total counts",

        data: [
          total_featured_catelog_count,
          // total_offers_count,
          // total_order_count,
          // total_package_count,
          // total_product_count,
          total_promo_count,
          total_rider_count,
          total_ticket_count,
          total_user_count,
          total_vendor_count,
        ],
      },
    ],
  };

  return (
    <div>
      {" "}
      <Chart
        options={state.options}
        series={state.series}
        type="bar"
        height={"350px"}
      />
    </div>
  );
};

export default BarChart;
