import "./App.css";

import { Route, Routes } from "react-router";
import AdminLayout from "./Pages/SuperAdmin/Layout/Layout";
import StaffLayout from "./Pages/Staff/Layout/Layout";
import Login from "./Pages/Login";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div>
      <Toaster />
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Admin/*" element={<AdminLayout />} />
        <Route path="/Staff/*" element={<StaffLayout />} />
      </Routes>
    </div>
  );
}

export default App;
