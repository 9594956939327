import React, { useState, useEffect } from "react";
import axios from "axios";
import { getCookie } from "../../../utils/Cookie";
import ViewTransactionModal from "./ViewTransactionModal";

import { AiFillEye } from "react-icons/ai";
import { Dialog, Spinner } from "@material-tailwind/react";
import MiniNav from "../../../Components/MiniNav";
import isEqual from "lodash/isEqual";

const Transactions = () => {
  const token = getCookie("_auth");
  // console.log("token", token);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const [openView, setOpenView] = useState(false);

  const [orderUser, setOrderUser] = useState({});
  const [order, setOrder] = useState({});

  const [transactions, settransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // loading the vendor's transactionss

    axios
      .get(
        `${process.env.REACT_APP_BASEURL}/transactions/?page=${page}`,
        config
      )
      .then((res) => {
        if (transactions && !isEqual(res.data.results, transactions)) {
          settransactions(res.data.results);
        }
        setCount(res.data.count);
        // console.log(res);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPage(1);
      });

    // axios
    //   .get(`${process.env.REACT_APP_BASEURL}/transactions/user_walet/`, config)
    //   .then((res) => {
    //     console.log(res.data);
    //   })
    //   .catch((err) => console.log(err));
  }, [transactions, page]);

  return (
    <>
      <div className="px-4">
        <MiniNav page={"Transactions"} />

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  # TRANSACTION ID
                </th>

                <th scope="col" className="px-6 py-3">
                  AMOUNT
                </th>

                <th scope="col" className="px-6 py-3">
                  DATE and TIME
                </th>
                <th scope="col" className="px-6 py-3">
                  Reference
                </th>
                <th scope="col" className="px-6 py-3">
                  STATUS
                </th>
                <th scope="col" className="px-6 py-3">
                  action
                </th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? " "
                : transactions.length === 0
                ? "No transactionss"
                : transactions.map((transactions) => (
                    <tr
                      key={transactions.id}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 ">{transactions.id}</td>
                      <td className="px-6 py-4">{transactions.amount}</td>
                      <td className="px-6 py-4">
                        {new Date(transactions.date_created).toLocaleString()}
                      </td>
                      <td className="px-6 py-4">{transactions.ref}</td>
                      <td
                        className={
                          transactions.status === "Success"
                            ? "px-6 py-4 text-green-600"
                            : "px-6 py-4 text-orange-600"
                        }
                      >
                        {transactions.status}
                      </td>
                      <td
                        className="px-6 py-4"
                        onClick={() => {
                          setOpenView(true);
                          setOrder(transactions.order);
                          setOrderUser(transactions.user);
                        }}
                      >
                        <AiFillEye size={30} />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>{" "}
          {loading && (
            <div className="h-40">
              <div className="w-full flex justify-center items-center h-full">
                <Spinner color="pink" className="h-12 w-12" />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end items-center gap-5 p-5 mb-4">
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page - 1)}
          >
            prev
          </button>
          <div>{page}</div>
          <div>/</div>
          <div>{Math.ceil(count / 10)}</div>
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page + 1)}
          >
            next
          </button>
        </div>
      </div>
      <Dialog open={openView} handler={() => setOpenView(!openView)}>
        <ViewTransactionModal order={order} orderUser={orderUser} />
      </Dialog>
    </>
  );
};

export default Transactions;
