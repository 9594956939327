import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { AiFillDelete, AiFillEye } from "react-icons/ai";

import { Avatar, Button, Dialog, Spinner } from "@material-tailwind/react";
import MiniNav from "../../../Components/MiniNav";
import { getCookie } from "../../../utils/Cookie";
import AddStaffModal from "./AddStaffModal";
import isEqual from "lodash/isEqual";
import ViewStaffModal from "./ViewStaffModal";

const Managestaffs = () => {
  const [staffs, setstaffs] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [selectedStaff, setSelectedStaff] = useState("");

  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [openView, setOpenView] = useState(false);
  //   console.log(staffs);

  const token = getCookie("_auth");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const deleteStaff = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BASEURL}/admin/staff/${id}`, config)
      .then(() => {
        Swal.fire("Submitted!", "Staff removed!", "success");
      })
      .catch((err) => {
        Swal.fire(
          "Staff",
          ` Failed to delete ${JSON.stringify(err.response.data)}`,
          "error"
        );

        console.log("error", err.response.data);
      });
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin/staff/?page=${page}`, config)
      .then((res) => {
        if (staffs && !isEqual(res.data.results, staffs)) {
          setstaffs(res.data.results);
        }
        setCount(res.data.count);
        setLoading(false);
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        setPage(1);
      });
  }, [staffs, page]);

  return (
    <div>
      {" "}
      <MiniNav page={"Manage staffs"} />
      <div className="px-4">
        <div className="flex justify-start items-center gap-5 w-full">
          <div className="flex justify-end items-center w-full p-4">
            <Button
              className="px-4 py-2 bg-pink-500 text-white rounded-lg"
              onClick={() => setOpenAdd(true)}
            >
              Add Staff
            </Button>
          </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #ID
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  STAFF
                </th> */}
                <th scope="col" className="px-6 py-3">
                  STAFF NAME
                </th>
                <th scope="col" className="px-6 py-3">
                  ADDRESS
                </th>
                <th scope="col" className="px-6 py-3">
                  EMAIL
                </th>
                <th scope="col" className="px-6 py-3">
                  PHONE
                </th>
                <th scope="col" className="px-6 py-3">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? ""
                : staffs.length === 0
                ? "No staffs"
                : staffs.map((Staff) => (
                    <tr
                      key={Staff.id}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 ">#{Staff.id}</td>
                      {/* <td className="px-6 py-4">
                        {" "}
                        <Avatar
                          size="lg"
                          src={Staff?.cover_photo}
                          alt={Staff.name}
                          className="text-center mx-auto"
                        />
                      </td> */}
                      <td className="px-6 py-4"> {Staff.user.fullname}</td>
                      <td className="px-6 py-4">{Staff.address}</td>
                      <td className="px-6 py-4">{Staff.user.email}</td>

                      <td className="px-6 py-4">{Staff.user.phone_number}</td>
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-3">
                          <button
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            onClick={() => {
                              setOpenView(true);
                              setSelectedStaff(Staff.id);
                            }}
                          >
                            <AiFillEye size={30} />
                          </button>

                          <button
                            className="font-medium text-red-600 dark:text-red-500 hover:underline"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteStaff(Staff.id);
                                }
                              });
                            }}
                          >
                            <AiFillDelete size={30} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {loading && (
            <div className="h-40">
              <div className="w-full flex justify-center items-center h-full">
                <Spinner color="pink" className="h-12 w-12" />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end items-center gap-5 p-5 mb-4">
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page - 1)}
          >
            prev
          </button>
          <div>{page}</div>
          <div>/</div>
          <div>{Math.ceil(count / 10)}</div>
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page + 1)}
          >
            next
          </button>
        </div>
      </div>
      <Dialog open={openAdd} handler={setOpenAdd}>
        <AddStaffModal openAdd={openAdd} setOpenAdd={setOpenAdd} />
      </Dialog>
      <Dialog open={openView} handler={setOpenView}>
        <ViewStaffModal ID={selectedStaff} />
      </Dialog>
    </div>
  );
};

export default Managestaffs;
