import React, { useEffect, useState } from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";

import axios from "axios";
import { getCookie, clearCookies } from "../../../utils/Cookie";
import { IconButton } from "@material-tailwind/react";

import ManageRiders from "./ManageRider";
import ApproveRiders from "./ApproveRider";

const Riders = () => {
  const [counts, setCounts] = useState({});
  // console.log(counts);
  const fetchCounts = () => {
    const token = getCookie("_auth");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin/admin_counts/`, config)
      .then((res) => {
        // console.log(res);
        setCounts(res.data);
        if (res.status === 401) {
          clearCookies();
          window.location = "/login";
        }
      })
      .catch((err) => {
        // console.log(err);
        fetchCounts();
      });
  };

  useEffect(() => {
    fetchCounts();
  });

  return (
    <div className="">
      <Tabs id="custom-animation" value={1}>
        <TabsHeader>
          <Tab className="text-pink-500" value={1}>
            Manage Riders
          </Tab>
          <Tab className="text-pink-500" value={2}>
            Approve Riders{" "}
            <IconButton className="rounded-full bg-pink-500">
              {counts.total_pending_rider_count}
            </IconButton>
          </Tab>
        </TabsHeader>
        <TabsBody
          animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          <TabPanel value={1}>
            <ManageRiders />
          </TabPanel>
          <TabPanel value={2}>
            <ApproveRiders />
          </TabPanel>
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default Riders;
