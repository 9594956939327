import React, { useState, useEffect } from "react";
import axios from "axios";
import { getCookie } from "../../../utils/Cookie";

// import { Context } from "../utils/Context";

const VeiwTicketModal = ({ Id }) => {
  const token = getCookie("_auth");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  // console.log(Id);

  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState("");
  //   console.log(Ticket);

  useEffect(() => {
    // loading the Tickets
    axios
      .get(`${process.env.REACT_APP_BASEURL}/ticketing/${Id}`, config)
      .then((res) => {
        // console.log(res);
        setTicket(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [Id]);

  return (
    <>
      {loading
        ? "loading Ticket"
        : ticket && (
            <>
              <div className="flex justify-start items-start bg-white border border-gray-200 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700">
              
                <div className="p-5">
                  <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  </h5>
                  <div className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                    {ticket.subject}
                    {ticket.message}
                    {ticket.support}
                    {ticket.status}
                  </div>
                </div>
              </div>
            </>
          )}
    </>
  );
};

export default VeiwTicketModal;
