import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getCookie } from "../../../utils/Cookie";
import { AiFillDelete, AiFillEye } from "react-icons/ai";

import { Avatar, Dialog, Spinner } from "@material-tailwind/react";
import VeiwPackageModal from "./ViewPackageModal";
import MiniNav from "../../../Components/MiniNav";

const Packages = () => {
  const token = getCookie("_auth");
  // console.log("token", token);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [openView, setOpenView] = useState(false);

  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState("");

  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [category, setCategory] = useState([]);
  const [selectedcategory, setSelectedCategory] = useState("");

  useEffect(() => {
    // loading the vendor's Packages
    // setLoading(true);
    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin/package/`, {
        params: {
          // shop_id: vendorId,
          package_name__icontains: searchTerm,
          package_category_id: selectedcategory,
          page: page,
        },
        config,
      })
      .then((res) => {
        setPackages(res.data.results);
        setCount(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPage(1);
      });

    axios
      .get(`${process.env.REACT_APP_BASEURL}/shop/package-category/`)
      .then((res) => {
        // console.log(res);
        setCategory(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, [packages, searchTerm, page, selectedcategory]);

  const deletePackage = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BASEURL}/admin/package/${id}`, config)
      .then(() => {
        Swal.fire("Submitted!", "Hamper removed!", "success");
      })
      .catch((err) => {
        Swal.fire(
          "Hamper",
          ` Failed to delete ${JSON.stringify(err.response.data)}`,
          "error"
        );
        console.log("error", err.response.data);
      });
  };

  return (
    <>
      <MiniNav page={"Hamper"} />
      <div className="px-4">
        {/* <div className="flex w-full flex-wrap gap-4 py-4 ">
          <div className="flex flex-1">
            <input
              type="search"
              className="block w-full h-12 p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-pink-500 focus:border-pink-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
              placeholder="Search ...  eg cake"
              onChange={(e) => setSearchTerm(e.target.value)}
              required
            />
          </div>
          <div className="">
            <select
              className="bg-gray-50 w-full h-12 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-fu p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
              type="select"
              name="category"
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="">All categories</option>
              {category.map((category) => (
                <option value={category.id} key={category.id}>
                  {category.name}
                </option>
              ))}
            </select>
          </div>
        </div> */}
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Hamper
                </th>
                <th scope="col" className="px-6 py-3">
                  Hamper NAME
                </th>

                <th scope="col" className="px-6 py-3">
                  PRICE
                </th>
                <th scope="col" className="px-6 py-3">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? ""
                : packages.length === 0
                ? "No hampers"
                : packages.map((Package) => (
                    <tr
                      key={Package.id}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 ">#{Package.id}</td>
                      <td className="px-6 py-4">
                        {" "}
                        <Avatar
                          size="lg"
                          src={Package?.image}
                          alt={Package.name}
                          className="text-center mx-auto"
                        />
                      </td>
                      <td className="px-6 py-4"> {Package.package_name}</td>
                      <td className="px-6 py-4">{Package.price}</td>

                      <td className="px-6 py-4">
                        <div className="flex items-center gap-3">
                          <button
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            onClick={() => {
                              setOpenView(true);
                              setSelectedPackage(Package);
                            }}
                          >
                            <AiFillEye size={30} />
                          </button>

                          <button
                            className="font-medium text-red-600 dark:text-red-500 hover:underline"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deletePackage(Package.id);
                                }
                              });
                            }}
                          >
                            <AiFillDelete size={30} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {loading && (
            <div className="h-40">
              <div className="w-full flex justify-center items-center h-full">
                <Spinner color="pink" className="h-12 w-12" />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end items-center gap-5 p-5 mb-4">
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page - 1)}
          >
            prev
          </button>
          <div>{page}</div>
          <div>/</div>
          <div>{Math.ceil(count / 10)}</div>
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page + 1)}
          >
            next
          </button>
        </div>
      </div>

      {/* veiw Package modal */}
      <Dialog open={openView} handler={() => setOpenView(!openView)}>
        <VeiwPackageModal Id={selectedPackage} />
      </Dialog>
    </>
  );
};

export default Packages;
