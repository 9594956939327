import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getCookie } from "../../../utils/Cookie";
import { AiFillDelete, AiFillEye } from "react-icons/ai";

import { Avatar, Dialog, Spinner } from "@material-tailwind/react";
import ViewOfferModal from "./ViewOfferModal";
import MiniNav from "../../../Components/MiniNav";
import isEqual from "lodash/isEqual";

const Offers = () => {
  const token = getCookie("_auth");
  // console.log("token", token);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");

  const [openView, setOpenView] = useState(false);

  const [Offers, setOffers] = useState([]);
  const [selectedOffer, setSelectedOffer] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // loading the vendor's Offers

    axios
      .get(
        `${process.env.REACT_APP_BASEURL}/admin/offers/?page=${page}&name=${searchTerm}`,
        config
      )
      .then((res) => {
        if (Offers && !isEqual(res.data.results, Offers)) {
          setOffers(res.data.results);
        }
        setCount(res.data.count);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPage(1);
      });
  }, [Offers, searchTerm, page]);

  const deleteOffer = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BASEURL}/admin/offers/${id}`, config)
      .then(() => {
        Swal.fire("Submitted!", "Offer removed!", "success");
      })
      .catch((err) => {
        Swal.fire(
          "Offer",
          ` Failed to delete ${JSON.stringify(err.response.data)}`,
          "error"
        );
        window.location.reload();

        console.log("error", err.response.data);
      });
  };

  return (
    <>
      <MiniNav page={"Offers"} />
      <div className="px-4">
        <div className="flex flex-1 py-4">
          <input
            type="search"
            className="block w-full h-12 p-2 pl-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-pink-500 focus:border-pink-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
            placeholder="Search ...  eg cake"
            onChange={(e) => setSearchTerm(e.target.value)}
            required
          />
        </div>

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #ID
                </th>
                <th scope="col" className="px-6 py-3">
                  Offer
                </th>
                <th scope="col" className="px-6 py-3">
                  Offer NAME
                </th>

                <th scope="col" className="px-6 py-3">
                  NEW PRICE
                </th>
                <th scope="col" className="px-6 py-3">
                  START DATE
                </th>
                <th scope="col" className="px-6 py-3">
                  END DATE
                </th>
                <th scope="col" className="px-6 py-3">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? ""
                : Offers.length === 0
                ? "No Offers"
                : Offers.map((Offer) => (
                    <tr
                      key={Offer.id}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 ">#{Offer.id}</td>
                      <td className="px-6 py-4">
                        {" "}
                        <Avatar
                          size="lg"
                          src={
                            // process.env.REACT_APP_BASEURL +
                            Offer?.image
                          }
                          alt={Offer.name}
                          className="text-center mx-autPackageCategoryo"
                        />
                      </td>

                      <td className="px-6 py-4"> {Offer.name}</td>
                      <td className="px-6 py-4">{Offer.new_price}</td>
                      <td className="px-6 py-4">{Offer.start_date}</td>
                      <td className="px-6 py-4">{Offer.end_date}</td>
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-3">
                          <button
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            onClick={() => {
                              setOpenView(true);
                              setSelectedOffer(Offer.id);
                            }}
                          >
                            <AiFillEye size={30} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {loading && (
            <div className="h-40">
              <div className="w-full flex justify-center items-center h-full">
                <Spinner color="pink" className="h-12 w-12" />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end items-center gap-5 p-5 mb-4">
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page - 1)}
          >
            prev
          </button>
          <div>{page}</div>
          <div>/</div>
          <div>{Math.ceil(count / 10)}</div>
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page + 1)}
          >
            next
          </button>
        </div>
      </div>

      {/* veiw Offer modal */}
      <Dialog open={openView} handler={() => setOpenView(!openView)}>
        <ViewOfferModal Id={selectedOffer} />
      </Dialog>
    </>
  );
};

export default Offers;

// onClick={() => {
//   setOpen(!open);
//   setSelectedOffer(Offer.id);
// }}
