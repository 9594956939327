import axios from "axios";
import { useState, useEffect } from "react";
import notify from "./notify"
import { getCookie } from "./Cookie";

export function useAxios() {

  const token = getCookie("_auth");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const ApiRequest = async (path, method, body, params) => {
    setIsLoading(true);
    setData(null);
    setError(null);
    const url = `${process.env.REACT_APP_BASEURL}${path}`;

    try {
      const res = await axios({
        method,
        url,
        params: params,
        data: body,
        ...config,
      });

      if (res.status >= 200 && res.status < 300) {
        setData(res?.data);
        setError(null);
        if (method !== "GET") {
          notify(res?.data?.message, "success");
        }
      }
    } catch (err) {
      console.log(err);
      if (method !== "GET") {
        notify(err?.response?.data?.message, "error");
      }
      setError(err?.response?.data);
    } finally {
      setIsLoading(false);
    }
  };

  return { data, error, isLoading, ApiRequest, config };
}
