import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

function AdduserModal({ openAdd, setOpenAdd }) {
  const [confirmPassword, setConfirmPassword] = useState("");
  const initialState = {
    username: "",
    fullname: "",
    email: "",
    password: "",
    phone_number: "",
    gender: "",
  };

  const [userData, setUserData] = useState(initialState);

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(userData);
    axios
      .post(`${process.env.REACT_APP_BASEURL}/user-register/`, userData)
      .then((res) => {
        setOpenAdd(!openAdd);
        Swal.fire("Register", `signup successful Proceed to login `, "success");
        // console.log(res.data);
      })
      .catch((err) => {
        setOpenAdd(!openAdd);
        Swal.fire(
          "Register",
          `signup Failed ${err.response.data.detail}`,
          "error"
        );
        console.log(err);
      });
  };

  return (
    <>
      <div className=" flex flex-col justify-center items-center w-full h-fit p-4">
        <div className="flex flex-col justify-center items-center w-full ">
          <h1 className="text-xl font-semibold"> Register a user.</h1>
        </div>
        <div className="flex justify-center items-start w-full p-4 lg:p-0 ">
          <div className="flex w-full flex-col gap-4">
            <div>
              {" "}
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                username
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                name="username"
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                fullame
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                name="fullname"
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                Email
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                name="email"
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                phone no.
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                name="phone_number"
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                gender
              </label>
              <select
                label="Select Gender"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                name="gender"
                onChange={handleChange}
                required
              >
                <option value="...">Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>

            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                password
              </label>
              <input
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                type="password"
                name="password"
                onChange={handleChange}
                required
              />
            </div>
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                confirm password
              </label>
              <input
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
                type="password"
                label="Comfirm Password"
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
              {userData.password === confirmPassword ? (
                ""
              ) : (
                <p className="text-red-600">Password must match..</p>
              )}
            </div>
            <div>
              <button
                type="submit"
                onClick={handleSubmit}
                className="w-full text-white bg-pink-500 p-2 rounded-full"
              >
                Add User
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdduserModal;
