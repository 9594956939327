import React, { useState, useEffect } from "react";
import axios from "axios";
import { getCookie } from "../../../utils/Cookie";
import { Spinner } from "@material-tailwind/react";

// import { Context } from "../utils/Context";

const VeiwOfferModal = ({ Id }) => {
  const token = getCookie("_auth");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  // console.log(Id);

  const [Offer, setOffer] = useState({});
  const [loading, setLoading] = useState(true);
  //   console.log(Offer);

  useEffect(() => {
    // loading the Offers
    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin/offers/${Id}`, config)
      .then((res) => {
        // console.log(res);
        setOffer(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [Id]);

  return (
    <>
      {loading ? (
        <div className="w-full flex justify-center items-center h-full">
          <Spinner color="pink" className="h-12 w-12" />
        </div>
      ) : (
        Offer && (
          <>
            <div className="flex justify-start items-start bg-white border border-gray-200 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700">
              <div className="w-1/2 rounded-xl">
                <img
                  className="rounded-t-lg w-full max-w-sm"
                  src={
                    Offer?.image
                      ? process.env.REACT_APP_BASEURL + Offer?.image
                      : "/dp.jpeg"
                  }
                  alt="dessert"
                />
              </div>

              <div className="flex flex-col gap-4 items-start justify-start p-5">
                <div className="flex gap-4 justify-center items-center">
                  <h1>Name</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Offer.name}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>start_date</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Offer.start_date}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>end_date</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Offer.end_date}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>description</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Offer.description}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>Offer_size</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Offer.Offer_size}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>New price</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Offer.new_price}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>in stock</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Offer.in_stock}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>shop</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Offer.shop}
                  </p>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default VeiwOfferModal;
