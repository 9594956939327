import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import Sms from "./Sms";
import Email from "./Email";

const Message = () => {
  return (
    <div className="">
      <Tabs id="custom-animation" value={1}>
        <TabsHeader>
          <Tab className="text-pink-500" value={1}>
            SMS
          </Tab>
          <Tab className="text-pink-500" value={2}>
            Emails
          </Tab>
        </TabsHeader>
        <TabsBody
          animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          <TabPanel value={1}>
            <Sms />
          </TabPanel>
          <TabPanel value={2}>
            <Email />
          </TabPanel>
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default Message;
