import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getCookie } from "../../../utils/Cookie";
import isEqual from "lodash/isEqual";

import { Avatar, Dialog, Spinner } from "@material-tailwind/react";
import ViewProductModal from "./ViewProductModal";
import MiniNav from "../../../Components/MiniNav";
import { AiFillDelete, AiFillEye } from "react-icons/ai";

const FeaturedProducts = () => {
  const token = getCookie("_auth");
  // console.log("token", token);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);

  const [openView, setOpenView] = useState(false);

  const [FeaturedProducts, setFeaturedProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // loading the vendor's FeaturedProducts

    axios
      .get(
        `${process.env.REACT_APP_BASEURL}/admin/featured-catalog/?page=${page}`,
        config
      )
      .then((res) => {
        if (FeaturedProducts && !isEqual(res.data.results, FeaturedProducts)) {
          setFeaturedProducts(res.data.results);
        } // console.log(res);
        setCount(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPage(1);
      });
  }, [FeaturedProducts, page]);

  const deleteFeaturedProduct = (id) => {
    axios
      .delete(
        `${process.env.REACT_APP_BASEURL}/admin/featured-catalog/${id}`,
        config
      )
      .then(() => {
        Swal.fire("Submitted!", "FeaturedProducts removed!", "success");
      })
      .catch((err) => {
        Swal.fire(
          "FeaturedProducts",
          ` Failed to delete ${JSON.stringify(err.response.data)}`,
          "error"
        );

        console.log("error", err.response.data);
      });
  };

  return (
    <>
      <MiniNav page={"FeaturedProducts"} />
      <div className="px-4">
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #ID
                </th>
                <th scope="col" className="px-6 py-3">
                  PRODUCT
                </th>
                <th scope="col" className="px-6 py-3">
                  PRODUCT NAME
                </th>
                <th scope="col" className="px-6 py-3">
                  PRICE
                </th>
                {/* <th scope="col" className="px-6 py-3">
                  SIZE
                </th> */}
                <th scope="col" className="px-6 py-3">
                  IN STOCK
                </th>
                <th scope="col" className="px-6 py-3">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? ""
                : FeaturedProducts.length === 0
                ? "No FeaturedProducts"
                : FeaturedProducts.map((product) => (
                    <tr
                      key={product.id}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 ">#{product.id}</td>
                      <td className="px-6 py-4">
                        {" "}
                        <Avatar
                          size="lg"
                          src={product?.products[0].image}
                          alt={product.name}
                          className="text-center mx-auto"
                        />
                      </td>
                      <td className="px-6 py-4"> {product.products[0].name}</td>
                      <td className="px-6 py-4">{product.products[0].price}</td>
                      {/* <td className="px-6 py-4">{product.products[0].size}</td> */}
                      <td className="px-6 py-4">
                        {product.products[0].in_stock}
                      </td>
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-3">
                          no action
                          {/* <button
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            onClick={() => {
                              setSelectedProduct(product.id);
                              setOpenView(true);
                            }}
                          >
                            <AiFillEye size={30} />
                          </button> */}
                          {/* <button
                            className="font-medium text-red-600 dark:text-red-500 hover:underline"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteFeaturedProduct(product.id);
                                }
                              });
                            }}
                          >
                            <AiFillDelete size={30} />
                          </button> */}
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {loading && (
            <div className="h-40">
              <div className="w-full flex justify-center items-center h-full">
                <Spinner color="pink" className="h-12 w-12" />
              </div>
            </div>
          )}
        </div>
        <div className="flex justify-end items-center gap-5 p-5 mb-4">
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page - 1)}
          >
            prev
          </button>
          <div>{page}</div>
          <div>/</div>
          <div>{Math.ceil(count / 10)}</div>
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page + 1)}
          >
            next
          </button>
        </div>
      </div>

      {/* veiw product modal */}
      <Dialog open={openView} handler={() => setOpenView(!openView)}>
        <ViewProductModal
          Id={selectedProduct}
          openView={openView}
          setOpenView={setOpenView}
        />
      </Dialog>
    </>
  );
};

export default FeaturedProducts;
