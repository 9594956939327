// import React, { useEffect, useState } from "react";
// import axios from "axios";
// import Swal from "sweetalert2";
// import { getCookie, getObjFromCookie } from "../../../utils/Cookie";
// import { Button, Input } from "@material-tailwind/react";
// import {
//   Tabs,
//   TabsHeader,
//   TabsBody,
//   Tab,
//   TabPanel,
// } from "@material-tailwind/react";

// const Banners = () => {
//   return (
//     <div className="">
//       <Tabs id="custom-animation" value={1}>
//         <TabsHeader>
//           <Tab className="text-pink-500" value={1}>
//             Vendors
//           </Tab>
//           <Tab className="text-pink-500" value={2}>
//             Riders{" "}
//           </Tab>
//         </TabsHeader>
//         <TabsBody
//           animate={{
//             initial: { y: 250 },
//             mount: { y: 0 },
//             unmount: { y: 250 },
//           }}
//         >
//           <TabPanel value={1}>
//             <Display link={"Vendor"} />{" "}
//           </TabPanel>
//           <TabPanel value={2}>
//             {" "}
//             <Display link="rider" />
//           </TabPanel>
//         </TabsBody>
//       </Tabs>
//     </div>
//   );
// };

// export default Banners;

import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import { useAxios } from "../../../utils/ApiHook";
import { Button, Dialog, Input } from "@material-tailwind/react";

const Banners = () => {
  // const { data, isLoading, ApiRequest } = useAxios();
  const [open, setOpen] = useState(false);
  const [selectedBanner, setselectedBanner] = useState(null);
  const [link, setLink] = useState("null");
  const handleOpen = () => setOpen(!open);

  const {
    data: dataRider,
    isLoading: loadingRider,
    ApiRequest: getRider,
  } = useAxios();
  const {
    data: dataVendor,
    isLoading: loadingVendor,
    ApiRequest: getVendor,
  } = useAxios();
  const {
    data: dataHome,
    isLoading: loadingHome,
    ApiRequest: getHome,
  } = useAxios();

  useEffect(() => {
    getRider("/admin/rider-display/", "GET", null, null);
    getVendor("/admin/vendor-display/", "GET", null, null);
    // getHome("/admin/home-display/", "GET", null, null);
  }, [open]);

  return (
    <div className="">
      <div className="space-y-4 ">
        {/* riders display */}
        <div className="space-y-4">
          {loadingRider ? (
            <div className="flex items-center justify-center h-40 border border-gray-400 bg-white rounded-lg">
              Loading...
            </div>
          ) : (
            dataRider?.results?.map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center w-full border border-gray-400 p-8 bg-white rounded-lg"
              >
                <img
                  src={item.image}
                  alt={item.title}
                  className="object-cover aspect-square h-40"
                />
                <div className="p-4">
                  <h1 className="text-2xl font-bold">{item.title}</h1>
                  <p className="">{item.desciption}</p>
                </div>
                <Button
                  onClick={() => {
                    setselectedBanner(item);
                    setLink("rider");
                    handleOpen();
                  }}
                  className="bg-pink-500 text-white rounded-lg "
                >
                  edit
                </Button>{" "}
              </div>
            ))
          )}
        </div>
        {/* vendors */}

        <div className="space-y-4">
          {loadingVendor ? (
            <div className="flex items-center justify-center h-40 border border-gray-400 bg-white rounded-lg">
              Loading...
            </div>
          ) : (
            dataVendor?.results?.map((item, index) => (
              <div
                key={index}
                className="flex justify-between items-center w-full border border-gray-400 p-8 bg-white rounded-lg"
              >
                <img
                  src={item.image}
                  alt={item.title}
                  className="object-cover aspect-square h-40"
                />
                <div className="p-4">
                  <h1 className="text-2xl font-bold">{item.title}</h1>
                  <p className="">{item.desciption}</p>
                </div>
                <Button
                  onClick={() => {
                    setselectedBanner(item);
                    setLink("vendor");
                    handleOpen();
                  }}
                  className="bg-pink-500 text-white rounded-lg "
                >
                  edit
                </Button>{" "}
              </div>
            ))
          )}
        </div>

        {/* <div className="flex justify-between items-center w-full border border-gray-400 p-8 bg-white rounded-lg">
          <img
            src={dataHome?.results[0]?.image}
            alt=""
            className="object-cover aspect-square h-40"
          />
          <Button disabled className="  bg-pink-500 text-white rounded-lg ">
            edit
          </Button>
        </div> */}
      </div>
      <Dialog open={open} handler={handleOpen}>
        <Toaster />

        <Display link={link} item={selectedBanner} />
      </Dialog>
    </div>
  );
};

export default Banners;

const Display = ({ link, item }) => {
  const { data, isLoading, ApiRequest } = useAxios();

  const [formData, setFormData] = useState(item);
  const [image, setImage] = useState(null);

  const onchangeHandler = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ formData });
    const formdata = new FormData();
    if (formData.title) {
      formdata.append("title", formData.title);
    }
    if (formData.desciption) {
      formdata.append("desciption", formData.desciption);
    }
    if (image) {
      formdata.append("image", image);
    }
    console.log(formdata.entries);
    ApiRequest(`/admin/${link}-display/${item.id}`, "PUT", formdata, null);
  };

  return (
    <div className="p-8">
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col justify-center gap-4">
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Banner Title:
            </label>
            <input
              name="title"
              value={formData.title}
              onChange={onchangeHandler}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
            />
          </div>{" "}
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Banner description:
            </label>
            <input
              name="desciption"
              value={formData?.desciption}
              onChange={onchangeHandler}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
            />
          </div>{" "}
          <div>
            <h1>Banner Image</h1>
            <Input
              type="file"
              name="image"
              accept="image/jpeg, image/png image/jpg image/gif"
              onChange={(e) => setImage(e.target.files[0])}
            />
          </div>
          <div>
            <Button
              type="submit"
              className="bg-pink-500 text-white rounded-lg w-full"
            >
              Add
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
