import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@material-tailwind/react";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "react-auth-kit";
import { ContextProvider } from "./utils/Context";
// import { ProSidebarProvider } from "react-pro-sidebar";
import App from "./App";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider
    authType={"cookie"}
    authName={"_auth"}
    cookieDomain={window.location.hostname}
    // cookieSecure={window.location.protocol === "https:"} //for production
    cookieSecure={false}
  >
    <BrowserRouter>
      <ThemeProvider>
        <ContextProvider>
          {/* <ProSidebarProvider> */}
            <App />{" "}
          {/* </ProSidebarProvider> */}
        </ContextProvider>
      </ThemeProvider>
    </BrowserRouter>
  </AuthProvider>
);
