import React, { createContext, useState, useEffect } from "react";

const Context = createContext();

const ContextProvider = ({ children }) => {
  // userdetails context
  const [user, setUser] = useState(null);

  // Load user data from local storage on initial render
  useEffect(() => {

    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      try {
        setUser(JSON.parse(storedUser));
      } catch (error) {
        setUser(storedUser);
      }
    }
  }, []);

  // Function to update the user data
  const updateUser = (userData) => {
    setUser(userData);
    localStorage.setItem("user", JSON.stringify(userData));
    // localStorage.setItem("user", userData);
  };

  const logout = () => {
    setUser(JSON.parse(null));
    localStorage.removeItem("user");
  };
  // userdetails context

  // Provide the all state and functions to the children components
  return (
    <Context.Provider
      value={{
        user,
        updateUser,
        logout,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Context, ContextProvider };
