import React, { useState, useEffect } from "react";

const ViewTransactionModal = ({ order, orderUser }) => {
  const [transactions, settransactions] = useState({});
  // console.log("order", order);
  // console.log("orderUser", orderUser);

  return (
    <>
      <>
        <div className="flex justify-start min-h-[400px] p-5 items-start bg-white border border-gray-200 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700">
          <div className="w-1/2 rounded-xl">
            <div className="flex flex-col gap-5 justify-center">
              <p className="text-xl font-bold">
                Customer Name{" "}
                <span className="font-normal ">{orderUser.fullname}</span>
              </p>
              <p className="text-xl font-bold">
                Customer UserName
                <span className="font-normal "> {orderUser.username}</span>
              </p>
              <p className="text-xl font-bold">
                Customer phone No.{" "}
                <span className="font-normal ">{orderUser.phone_number}</span>
              </p>
              <p className="text-xl font-bold">
                Customer email
                <span className="font-normal "> {orderUser.email}</span>
              </p>
              <p className="text-xl font-bold">
                Customer gender
                <span className="font-normal "> {orderUser.gender}</span>
              </p>
            </div>
          </div>
          <div className="p-5">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              Ordered{" "}
            </h5>
            <div className="mb-3 font-normal text-gray-700 dark:text-gray-400">
              <div>
                <p className="text-xl font-bold">
                  date ordered{" "}
                  <span className="font-normal "> {order.date_added}</span>
                </p>
                <p className="text-xl font-bold">
                  date delivered{" "}
                  <span className="font-normal ">
                    {" "}
                    {order.date_of_delivery}
                  </span>
                </p>
                <p className="text-xl font-bold">
                  delivery amount{" "}
                  <span className="font-normal "> {order.delivery_amount}</span>
                </p>
                <p className="text-xl font-bold">
                  Total amount{" "}
                  <span className="font-normal "> {order.total_amount}</span>
                </p>
                <p className="text-xl font-bold">
                  Total quantity{" "}
                  <span className="font-normal "> {order.total_quantity}</span>
                </p>
                <p className="text-xl font-bold">
                  Status <span className="font-normal "> {order.status}</span>
                </p>
              </div>
              {/* <div>
                items
                {order.items.map((item) => (
                  <div key={item.id}>
                    <p className="text-xl font-bold">
                      product Name{" "}
                      <span className="font-normal "> {item.product}</span>
                    </p>
                    <p className="text-xl font-bold">
                      product price{" "}
                      <span className="font-normal "> {item.price}</span>
                    </p>
                    <p className="text-xl font-bold">
                      product_size
                      <span className="font-normal "> {item.product_size}</span>
                    </p>
                    <p className="text-xl font-bold">
                      product quantity{" "}
                      <span className="font-normal ">{item.quantity}</span>
                    </p>
                  </div>
                ))}
              </div> */}
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default ViewTransactionModal;
