import React from "react";
import { NavLink } from "react-router-dom";
import Logo from "../../../Assets/logo.png";

import UserNavProfile from "../../../Components/UserNavProfile";

const Navbar = () => {
  return (
    <div>
      <nav className="flex justify-between bg-white px-4">
        <div className="flex items-center justify-start">
          <NavLink to="/" className="flex ml-4 md:mr-24">
            <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
              <img
                src={Logo}
                className="max-w-[100px] h-12 lg:h-fit "
                alt="logo"
              />
            </span>
          </NavLink>
        </div>
        <div className="px-3 py-1 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <div className="flex items-center ml-3">
                <UserNavProfile />
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
