import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { Spinner } from "@material-tailwind/react";
import { getCookie } from "../../../utils/Cookie";

import FroalaEditor from "react-froala-wysiwyg";

//Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
// Load all plugins
import "froala-editor/js/plugins.pkgd.min.js";

const Email = () => {
  const token = getCookie("_auth");

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [userType, setUserType] = useState("");
  const [editorContent, setEditorContent] = useState("");
  const [loading, setLoading] = useState(false);

  const handleModelChange = (e) => {
    setEditorContent(e);
  };

  // handle the submission of the form
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    console.log(editorContent);
    const formdata = new FormData();
    formdata.append("user_type", userType);
    formdata.append("message", editorContent);

    axios
      .post(`${process.env.REACT_APP_BASEURL}/admin/--/`, formdata, config)
      .then((res) => {
        setLoading(false);
        console.log(res);
        Swal.fire("sucessfully", "email sent ", "success");
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        Swal.fire("error", `email Failed ${err.response.data.detail}`, "error");
      });
  };
  return (
    <div>
      {loading && (
        <div className="w-full flex justify-center items-center ">
          <div className="flex justify-center items-center gap-4 w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800">
            <Spinner color="pink" />
            <div className="text-sm font-normal">
              {" "}
              Please wait while Sending Email
            </div>
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col w-full gap-8">
          <div>
            <h1>Receiver's</h1>
            <select
              className=" bg-gray-50 w-full border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-fu p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500"
              type="select"
              name="user_type"
              onChange={(e) => setUserType(e.target.value)}
            >
              <option value="">Select</option>
              <option value="user">Users</option>
              <option value="vendor">Vendors</option>
              <option value="rider">Riders</option>
            </select>
          </div>

          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
              Your message
            </label>
            <FroalaEditor
              tag="textarea"
              model={editorContent}
              onModelChange={handleModelChange}
              config={{
                heightMin: 300,
                placeholderText: "Edit Your Message Here!",
              }}
              //   className="min-h-[40opx]"
            />
          </div>
          <div>
            <button
              type="submit"
              className="flex gap-3 items-end justify-center px-8 py-4 text-xl bg-pink-500 text-white rounded-lg disabled:bg-gray-400"
              disabled={loading}
            >
              Send
              {loading && <Spinner color="pink" />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Email;
