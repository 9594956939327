import React, { useState, useEffect } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Spinner } from "@material-tailwind/react";

const MapContainer = ({ selected, setSelected }) => {
  console.log(selected);
  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: 5.5557169,
    lng: -0.196306,
  };
  const [mapCenter, setMapCenter] = useState(center);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries: ["maps", "places"],
  });

  const [markers, setMarkers] = useState([]);

  const handleMarkerDragEnd = (event) => {
    setSelected({ lat: event.latLng.lat(), lng: event.latLng.lng() });
    setMarkers([
      {
        position: { lat: event.latLng.lat(), lng: event.latLng.lng() },
        visible: true,
      },
    ]);
  };

  useEffect(() => {
    const defaultMarker = { position: center, visible: true };
    setMarkers([defaultMarker]);
  }, []);

  useEffect(() => {
    if (selected) {
      const selectedMarker = { position: selected, visible: true };
      setMarkers((markers) => [...markers, selectedMarker]);
    }
  }, [selected]);

  const [address, setAddress] = useState("");

  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleSelect = async (address) => {
    const results = await geocodeByAddress(address);
    const latLng = await getLatLng(results[0]);

    setSelected({ lat: latLng.lat, lng: latLng.lng });
    setAddress(address);

    setMarkers([
      {
        position: { lat: latLng.lat, lng: latLng.lng },
        visible: true,
      },
    ]);
    setMapCenter({ lat: latLng.lat, lng: latLng.lng });
  };

  return isLoaded ? (
    <div>
      <PlacesAutocomplete
        value={address}
        onChange={handleAddressChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="p-2">
            <input
              {...getInputProps({
                placeholder: "Search Places...",
                className: "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-pink-500 focus:border-pink-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-pink-500 dark:focus:border-pink-500",
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div> <div className="w-full flex justify-center items-center h-full">
              <Spinner color="pink" className="h-12 w-12" />
            </div></div>}
              {suggestions.map((suggestion, index) => {
                const className = suggestion.active
                  ? "bg-blue-500 text-white px-2 py-1 cursor-pointer"
                  : "bg-white text-gray-700 px-2 py-1 cursor-pointer";
                return (
                  <div
                    key={index}
                    {...getSuggestionItemProps(suggestion, { className })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
      <GoogleMap
        id="Dessertstodoor"
        mapContainerStyle={containerStyle}
        zoom={15}
        center={mapCenter}
      >
        {markers.map((marker, index) => (
          <Marker
            key={index}
            position={marker.position}
            draggable={true}
            onDragEnd={handleMarkerDragEnd}
            visible={true}
          />
        ))}
      </GoogleMap>
    </div>
  ) : (
    <></>
  );
};

export default MapContainer;
