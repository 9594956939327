import React from "react";
import { FaHome, FaArrowRight } from "react-icons/fa";

const MiniNav = ({ page }) => {
  return (
    <nav className="flex px-5 py-1 text-gray-700  rounded-lg bg- white dark:bg-[#294f8b] my-4 ">
      <div className="inline-flex items-center space-x-1 md:space-x-3">
        <div className="flex items-center">
          <FaHome />{" "}
          <p className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
            Home
          </p>
        </div>
        <div className="flex items-center">
          <FaArrowRight />{" "}
          <p className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
            {page}
          </p>
        </div>
      </div>
    </nav>
  );
};

export default MiniNav;
