import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import isEqual from "lodash/isEqual";
import { FcDeleteRow,FcApproval } from "react-icons/fc"; 
import { AiFillDelete, AiFillEye } from "react-icons/ai";

import { Button, Dialog, Spinner } from "@material-tailwind/react";
import MiniNav from "../../../Components/MiniNav";
import { getCookie } from "../../../utils/Cookie";
import AddRiderModal from "./AddRiderModal";
import ViewRiderModal from "./ViewRiderModal";

const ApproveRider = () => {
  const [riders, setriders] = useState([]);
  // console.log(riders);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [selectedRider, setSelectedRider] = useState("");

  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [openView, setOpenView] = useState(false);
  //   console.log(riders);

  const token = getCookie("_auth");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const deleteRider = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BASEURL}/admin/rider/${id}`, config)
      .then(() => {
        Swal.fire("Submitted!", "Rider removed!", "success");
      })
      .catch((err) => {
        Swal.fire(
          "Rider",
          ` Failed to delete ${JSON.stringify(err.response.data)}`,
          "error"
        );

        console.log("error", err.response.data);
      });
  };
  const ApproveRider = (id) => {
    // console.log(id);
    // /admin/approve_Rider/
    setLoading(true);
    const body = {
      rider_id: id,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/approve_rider/`,
        body,
        config
      )
      .then(() => {
        Swal.fire("Submitted!", "Rider Approve!", "success");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Rider",
          ` Failed to Approve ${JSON.stringify(err.response.data)}`,
          "error"
        );
      });
  };
  const DeclineRider = (id) => {
    console.log(id);
    const body = {
      rider_id: id,
    };
    axios
      .post(
        `${process.env.REACT_APP_BASEURL}/admin/decline_rider/`,
        body,
        config
      )
      .then(() => {
        Swal.fire("Submitted!", "Rider Decline!", "success");
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "Rider",
          ` Failed to Decline ${JSON.stringify(err.response.data)}`,
          "error"
        );
      });
  };
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_BASEURL}/admin/rider/?status={"Pending"}&page=${page}`,
        config
      )
      .then((res) => {

        if (riders && !isEqual(res.data.results, riders)) {
          setriders(res.data.results);
        }        setCount(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPage(1);
      });
  }, [riders, page]);

  return (
    <div>
      {" "}
      <MiniNav page={"Approve riders"} />
      <div className="mt-8">
        <div className="flex justify-end items-center w-full p-4">
          <Button
            className="px-4 py-2 bg-pink-500 text-white rounded-lg"
            onClick={() => setOpenAdd(true)}
          >
            Add Rider
          </Button>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #ID
                </th>
                <th scope="col" className="px-6 py-3">
                  NAME
                </th>
                <th scope="col" className="px-6 py-3">
                  ADDRESS
                </th>
                <th scope="col" className="px-6 py-3">
                  CONTACT
                </th>
                <th scope="col" className="px-6 py-3">
                  STATUS
                </th>
                <th scope="col" className="px-6 py-3">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? ""
                : riders.length === 0
                ? "No riders"
                : riders.map((Rider) => (
                    <tr
                      key={Rider.id}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 ">{Rider.id}</td>
                      <td className="px-6 py-4"> {Rider.user.fullname}</td>
                      <td className="px-6 py-4">{Rider.residential_address}</td>
                      <td className="px-6 py-4">{Rider.user.phone_number}</td>

                      <td className="px-6 py-4">{Rider.status}</td>
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-3">
                          <button
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            onClick={() => {
                              setOpenView(true);
                              setSelectedRider(Rider.id);
                            }}
                          >
                            <AiFillEye size={30} />
                          </button>

                          {/* <button
                            className="font-medium text-red-600 dark:text-red-500 hover:underline"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteRider(Rider.id);
                                }
                              });
                            }}
                          >
                            <AiFillDelete size={30} />
                          </button> */}
                          <button
                            className="font-medium text-green-600 dark:text-green-500 hover:underline"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, Approve it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  ApproveRider(Rider.id);
                                }
                              });
                            }}
                          >
                            <FcApproval size={30} />
                          </button>
                          <button
                            className="font-medium text-orange-400 dark:text-orange-400 hover:underline"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, Decline it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  DeclineRider(Rider.id);
                                }
                              });
                            }}
                          >
                            <FcDeleteRow size={30}/>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {loading && (
            <div className="h-40">
              <div className="w-full flex justify-center items-center h-full">
                <Spinner color="pink" className="h-12 w-12" />
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-end items-center gap-5 p-5 mb-4">
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page - 1)}
          >
            prev
          </button>
          <div>{page}</div>
          <div>/</div>
          <div>{Math.ceil(count / 10)}</div>
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page + 1)}
          >
            next
          </button>
        </div>
      </div>
      <Dialog open={openAdd} handler={() => setOpenAdd(!openAdd)}>
        <AddRiderModal openAdd={openAdd} setOpenAdd={setOpenAdd} />
      </Dialog>
      <Dialog open={openView} handler={() => setOpenView(!openView)}>
        <ViewRiderModal ID={selectedRider} />
      </Dialog>
    </div>
  );
};

export default ApproveRider;
