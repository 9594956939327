import React, { useState, useEffect } from "react";
import axios from "axios";
import { getCookie } from "../../../utils/Cookie";
import { Spinner } from "@material-tailwind/react";

// import { Context } from "../utils/Context";

const ViewPackageModal = ({ Id }) => {
  const token = getCookie("_auth");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  // console.log(Id);

  const [Package, setPackage] = useState({});
  const [loading, setLoading] = useState(true);
  //   console.log(Package);

  useEffect(() => {
    // loading the Packages
    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin/package/${Id}`, config)
      .then((res) => {
        // console.log(res);
        setPackage(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [Id]);

  return (
    <>
      {loading ? (
        <div className="h-40">
          <div className="w-full flex justify-center items-center h-full">
            <Spinner color="pink" className="h-12 w-12" />
          </div>
        </div>
      ) : (
        Package && (
          <>
            <div className="flex justify-start items-start bg-white border border-gray-200 rounded-xl shadow dark:bg-gray-800 dark:border-gray-700">
              <div className="w-1/2 rounded-xl">
                <img
                  className="rounded-t-lg w-full h-80 max-w-sm"
                  src={
                    Package?.image
                      ? process.env.REACT_APP_BASEURL + Package?.image
                      : "/dp.jpeg"
                  }
                  alt="dessert"
                />
              </div>
              <div className="flex flex-col gap-4 items-start justify-start p-5">
                <div className="flex gap-4 justify-center items-center">
                  <h1>Name</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Package.package_name}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>description</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Package.description}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>Package_size</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Package.Package_size}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>add_ons</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Package.add_ons}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>price</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Package.price}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>in stock</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Package.in_stock}
                  </p>
                </div>
                <div className="flex gap-4 justify-center items-center">
                  <h1>shop</h1>

                  <p className="font-bold tracking-tight text-gray-900 dark:text-white">
                    {Package.shop}
                  </p>
                </div>
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};

export default ViewPackageModal;
