import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getObjFromCookie } from "../../../utils/Cookie";
import { Route, Routes } from "react-router";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import AdminDashboard from "../Dashboard/AdminDashboard";
import Offers from "../Offers/Offers";
import Promo from "../Promo/Promo";
import Staffs from "../Staffs/Staffs";
import Transactions from "../Transactions/Transactions";
import Tickets from "../Tickets/Tickets";
import Users from "../Users/Users";
import Settings from "../Settings/Settings";
import ProductLayout from "../Products/ProductLayout";
import PackageLayout from "../Packages/PackageLayout";
import Vendors from "../Vendors/Vendors";
import Riders from "../Riders/Riders";
import Message from "../Bulk-message/Message";
import Banners from "../Banner/Banners";



const Layout = () => {
  const user = getObjFromCookie("userdetails");
  // console.log(user);

  const navigate = useNavigate();
  useEffect(() => {
    if (!user) return navigate("/");
    if (!user.is_superuser === true) {
      navigate("/");
    }
  },[user]);

  return (
    <div className="min-h-screen w-full ">
      <Navbar/>
      {/* Content */}
      <div className="flex h-full">
      <Sidebar />
      <div className="bg-logo bg-no-repeat bg-contain bg-center bg-gray-400  w-full min-h-screen lg:px-20 lg:pt-10 px-4 py-2 ">
            <Routes>
              <Route path="/dashboard" element={<AdminDashboard />} />
              <Route path="/products" element={<ProductLayout />} />
              <Route path="/hampers" element={<PackageLayout />} />
              <Route path="/offers" element={<Offers />} />
              
              <Route path="/promo" element={<Promo />} />

              <Route path="/staffs" element={<Staffs />} />
              <Route path="/users" element={<Users />} />
              <Route path="/vendors" element={<Vendors />} />
              <Route path="/riders" element={<Riders />} />
              
              <Route path="/transactions" element={<Transactions />} />
             
              <Route path="/tickets" element={<Tickets />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/message" element={<Message />} />

              <Route path="/banners" element={<Banners />} />
            </Routes>
          </div>
        </div>
      </div>
  );
};

export default Layout;
