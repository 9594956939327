import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import Packages from "./Packages";
import PackageCategories from "./PackageCategories";
const PackageLayout = () => {
  return (
    <div className="">
      <Tabs id="custom-animation" value={1}>
        <TabsHeader>
          <Tab className="text-pink-500" value={1}>
            Hampers
          </Tab>
          <Tab className="text-pink-500" value={2}>
            Hampers Categories
          </Tab>
        </TabsHeader>
        <TabsBody
          animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          <TabPanel value={1}>
            <Packages />
          </TabPanel>
          <TabPanel value={2}>
            <PackageCategories />
          </TabPanel>
        </TabsBody>
      </Tabs>
    </div>
  );
};

export default PackageLayout;
