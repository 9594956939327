import React, { useState, useEffect } from "react";
import { saveToCookie } from "../utils/Cookie";
import axios from "axios";
import Swal from "sweetalert2";
import Logo from "../Assets/logo.png";
import { useNavigate } from "react-router";
import { useSignIn } from "react-auth-kit";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Typography,
} from "@material-tailwind/react";

const Login = () => {
  const signIn = useSignIn();

  const navigate = useNavigate();

  const initialState = {
    username: "",
    password: "",
  };

  const [userData, setUserData] = useState(initialState);
  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post(`${process.env.REACT_APP_BASEURL}/login/`, userData)
      .then((res) => {
        console.log(res);
        saveToCookie("userdetails", res.data, 1);
        signIn({
          token: res.data.access,
          user: res.data,
          expiresIn: 3600,
          tokenType: "Bearer",
          authState: res.data.is_active,
        });

        if (res.data.is_admin_staff === true) {
          navigate("/staff/dashboard");
          Swal.fire("SignIn", `Signin successful`, "success");
        } else if (res.data.is_superuser === true) {
          navigate("/admin/dashboard");
          Swal.fire("SignIn", `Signin successful`, "success");
        } else {
          Swal.fire({
            title: "Signin decline ?",
            text: "You can not access this routes!",
            icon: "warning",
            // showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Redirect Me!",
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.replace("https://dessertstodoor.com/login");
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
        Swal.fire(
          "error",
          `Sign In Failed ${err.response.data.detail}`,
          "error"
        );
      });
  };

  useEffect(() => {});

  return (
    <>
      <section className=" gradient-form h-screen w-full">
        <div className="bg-loginBg bg-cover bg-center rounded-lg shadow-lg flex h-full flex-wrap items-center justify-center">
          <div className="g-0 lg:flex lg:flex-wrap p-4">
            <div className="flex flex-col items-center bg-transparent border border-gray-200 rounded-lg shadow md:flex-row  bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
              <div className="p-8 lg:p-20 text-center">
                <img className="mx-auto w-48" src={Logo} alt="logo" />
                <h4 className="mb-12 mt-1 pb-1 text-xl font-semibold">
                  Welcome to Admins Dashboard DessertToDoor
                </h4>
                <Card color="transparent" shadow={false}>
                  <Typography variant="h4" color="pink-gray">
                    Sign In
                  </Typography>
                  <Typography color="gray" className="mt-1 font-normal">
                    Enter your details to Login.
                  </Typography>
                  <form onSubmit={handleSubmit} className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
                    <div className="mb-4 flex flex-col gap-6">
                      <Input
                        label="UserName"
                        name="username"
                        onChange={handleChange}
                        size="lg"
                      />
                      <Input
                        label="Password"
                        type="password"
                        name="password"
                        onChange={handleChange}
                        size="lg"
                      />
                    </div>

                    <Checkbox
                      className="checked:bg-pink-500 checked:border-pink-600 border-pink-600 checked:outline-pink-600 outline-pink-600"
                      label={
                        <Typography
                          variant="small"
                          color="gray"
                          className="flex items-center font-normal checked:bg-pink-500"
                        >
                          I agree the
                          <a
                            href="https://dessertstodoor.com/privacy"
                            target="_blank"
                            rel="noreferrer"
                            className="font-medium transition-colors hover:text-pink-500"
                          >
                            &nbsp;Terms and Conditions
                          </a>
                        </Typography>
                      }
                      containerProps={{ className: "-ml-2.5" }}
                    />
                    <Button
                      className="mt-6 w-full text-white bg-pink-500 hover:bg-pink-600 p-2 rounded-full"
                      fullWidth type="submit"
                      // onClick={handleSubmit}
                    >
                      Login
                    </Button>
                  </form>
                </Card>
              </div>

              {/* <div className="flex flex-col justify-between p-4 leading-normal">
                <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                  We are more than just a a delivery company
                </h5>
                <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                  We are more than just a a delivery company
                </p>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Login;
