import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getCookie } from "../../../utils/Cookie";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import isEqual from "lodash/isEqual";

import ViewTicketsModal from "./ViewTicketsModal";
import MiniNav from "../../../Components/MiniNav";
import { Dialog, Spinner } from "@material-tailwind/react";

const Tickets = () => {
  const token = getCookie("_auth");
  // console.log("token", token);
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const [openView, setOpenView] = useState(false);

  const [tickets, setTickets] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState("");

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // loading all Tickets
    axios
      .get(`${process.env.REACT_APP_BASEURL}/ticketing/`, config)
      .then((res) => {
        // setTickets(res.data);
        if (Tickets && !isEqual(res.data, Tickets)) {
          setTickets(res.data);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [tickets]);

  const deleteTickets = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BASEURL}/ticketing/${id}`, config)
      .then(() => {
        Swal.fire("Submitted!", "Tickets removed!", "success");
      })
      .catch((err) => {
        Swal.fire(
          "Tickets",
          ` Failed to delete ${JSON.stringify(err.response.data)}`,
          "error"
        );
        console.log("error", err.response.data);
      });
  };

  return (
    <>
      <div className="p-4">
        <MiniNav page={"Tickets"} />

        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #ID
                </th>

                <th scope="col" className="px-6 py-3">
                  SUBJECT
                </th>
                <th scope="col" className="px-6 py-3">
                  MESSAGE
                </th>
                <th scope="col" className="px-6 py-3">
                  SUPPORT
                </th>
                <th scope="col" className="px-6 py-3">
                  STATUS
                </th>
                <th scope="col" className="px-6 py-3">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? " "
                : tickets.length === 0
                ? " No Tickets"
                : tickets.map((Tickets) => (
                    <tr
                      key={Tickets.id}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 ">{Tickets.id}</td>
                      <td className="px-6 py-4"> {Tickets.subject}</td>
                      <td className="px-6 py-4"> {Tickets.message}</td>
                      <td className="px-6 py-4"> {Tickets.support}</td>
                      <td className="px-6 py-4">
                        {" "}
                        {Tickets.status === "true" ? " solved" : "Processing"}
                      </td>

                      <td className="px-6 py-4">
                        <div className="flex items-center gap-3">
                          <button
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            onClick={() => {
                              setOpenView(true);
                              setSelectedTickets(Tickets.id);
                            }}
                          >
                            <AiFillEye size={30} />
                          </button>

                          <button
                            className="font-medium text-red-600 dark:text-red-500 hover:underline"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteTickets(Tickets.id);
                                }
                              });
                            }}
                          >
                            <AiFillDelete size={30} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>{" "}
          {loading && (
            <div className="h-40">
              <div className="w-full flex justify-center items-center h-full">
                <Spinner color="pink" className="h-12 w-12" />
              </div>
            </div>
          )}
        </div>
      </div>

      <Dialog open={openView} handler={() => setOpenView(!openView)}>
        <ViewTicketsModal Id={selectedTickets} />
      </Dialog>
    </>
  );
};

export default Tickets;
