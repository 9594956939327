import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { getCookie } from "../../../utils/Cookie";

function AddStaffModal({ openAdd, setOpenAdd }) {
  const token = getCookie("_auth");
  // console.log("token", token);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [confirmPassword, setConfirmPassword] = useState("");

  const initialstate = {
    fullname: "",
    email: "",
    gender: "",
    phone_number: "",
    address: "",
    username: "",
    password: "",
  };
  const [formData, setFormData] = useState(initialstate); // store the form data in an object

  const [coverPhoto, setCoverPhoto] = useState(null);
  const [ghanaCard, setGhanaCard] = useState(null);

  // handle the submission of the form
  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log("formData", formData);

    const formdata = new FormData();

    formdata.append("ghanaCard", ghanaCard);
    formdata.append("cover_photo", coverPhoto);

    formdata.append("fullname", formData.fullname);
    formdata.append("email", formData.email);
    formdata.append("gender", formData.gender);
    formdata.append("shop_name", formData.shop_name);
    formdata.append("address", formData.address);
    formdata.append("username", formData.username);
    formdata.append("phone_number", formData.phone_number);
    formdata.append("password", formData.password);

    // console.log(
    //   "files",
    //   coverPhoto,
    //   ghanaCard
    // );

    // you can send the formData to an API
    axios
      .post(`${process.env.REACT_APP_BASEURL}/admin/staff/`, formdata, config)
      .then(() => {
        setOpenAdd(!openAdd);
        Swal.fire("Created", "Staff created sucessfully", "success");
      })
      .catch((err) => {
        setOpenAdd(!openAdd);

        // console.log(err);
        Swal.fire(
          "error",
          `Staff creation Failed ${err.response.data.detail}`,

          "error"
        );
      });
  };

  return (
    <>
      <div className=" flex flex-col justify-center items-center w-full h-fit p-4">
        <div className="flex flex-col justify-center items-center w-full ">
          <h1 className="text-xl font-semibold"> Register a Staff.</h1>
        </div>
        <div className="flex justify-center items-start w-full p-4 lg:p-0 ">
          <div className="container">
            <form onSubmit={handleSubmit}>
              <>
                <div className="grid gap-4 md:grid-cols-2">
                  <div>
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      FullName
                    </label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="John"
                      value={formData.fullname}
                      name="fullname"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div>
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      UserName
                    </label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="John93"
                      name="username"
                      value={formData.username}
                      onChange={handleChange}
                      required
                    />
                  </div>

                  <div>
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      Gender
                    </label>
                    <select
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                    >
                      <option value="">Choose...</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </select>
                  </div>

                  <div className="">
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      Email address
                    </label>
                    <input
                      type="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="email"
                      placeholder="john.doe@gmail.com"
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="">
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      Phone No.
                    </label>
                    <input
                      type="tel"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="phone_number"
                      placeholder="024 8945...."
                      value={formData.phone_number}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="">
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      address
                    </label>
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      name="address"
                      placeholder="Eg. American house,"
                      value={formData.address}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="">
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      Password
                    </label>
                    <input
                      type="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="•••••••••"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="">
                    <label className="block mb-1 text-sm font-medium text-gray-900 dark:text-white">
                      Confirm password
                    </label>
                    <input
                      type="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="•••••••••"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      value={confirmPassword}
                      required
                    />
                    {formData.password === confirmPassword ? (
                      ""
                    ) : (
                      <p className="text-red-600">Password must match..</p>
                    )}
                  </div>
                </div>

                <div>
                  <label className="flex justify-center items-center gap-4 w-full text-center mb-1 text-sm font-medium text-gray-900 dark:text-white">
                    <h1> Profile Photo </h1>{" "}
                    <p className="text-xs">what users see:</p>
                  </label>
                  <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    type="file"
                    onChange={(e) => setCoverPhoto(e.target.files[0])}
                    required
                  />

                  <label className="flex justify-center items-center gap-4 w-full text-center mb-1 text-sm font-medium text-gray-900 dark:text-white">
                    <h1> Owner's Ghana-card</h1>{" "}
                    <p className="text-xs">Optional</p>
                  </label>
                  <input
                    className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                    type="file"
                    onChange={(e) => setGhanaCard(e.target.files[0])}
                    // required
                  />
                </div>
                <div className="flex justify-end items-end  gap-8 pt-8">
                  <button
                    type="submit"
                    className="text-white bg-pink-500 py-2 px-8 rounded-full"
                  >
                    Submit
                  </button>
                </div>
              </>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddStaffModal;
