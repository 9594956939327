import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";

import { AiFillDelete, AiFillEye } from "react-icons/ai";

import { Button, Dialog, Spinner } from "@material-tailwind/react";
import MiniNav from "../../../Components/MiniNav";
import { getCookie } from "../../../utils/Cookie";
import AdduserModal from "./AddUserModal";
import ViewuserModal from "./ViewUserModal";

const Users = () => {
  const [users, setusers] = useState([]);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [selecteduser, setSelecteduser] = useState("");

  const [loading, setLoading] = useState(true);
  const [openAdd, setOpenAdd] = useState(false);
  const [openView, setOpenView] = useState(false);

  const token = getCookie("_auth");

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  };
  const deleteuser = (id) => {
    axios
      .delete(`${process.env.REACT_APP_BASEURL}/admin/user/${id}`, config)
      .then(() => {
        Swal.fire("Submitted!", "user removed!", "success");
      })
      .catch((err) => {
        Swal.fire(
          "user",
          ` Failed to delete ${JSON.stringify(err.response.data)}`,
          "error"
        );

        console.log("error", err.response.data);
      });
  };
  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_BASEURL}/admin/user/?page=${page}`, config)
      .then((res) => {
        setusers(res.data.results);
        setCount(res.data.count);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setPage(1);
      });
  }, [users, page]);

  return (
    <div>
      {" "}
      <MiniNav page={"Manage users"} />
      <div className="mt-8">
        <div className="flex justify-between items-center gap-5 w-full p-4">
          {/* <div className="block max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
              counts
            </h5>
            <p className="font-normal text-gray-700 dark:text-gray-400">
              {count}
            </p>
          </div> */}
          <div className="flex justify-end items-center w-full p-4">
            <Button
              className="px-4 py-2 bg-pink-500 text-white rounded-lg"
              onClick={() => setOpenAdd(true)}
            >
              Add user
            </Button>
          </div>
        </div>
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  #ID
                </th>
                <th scope="col" className="px-6 py-3">
                  USERNAME
                </th>
                <th scope="col" className="px-6 py-3">
                  CONTACT
                </th>
                <th scope="col" className="px-6 py-3">
                  EMAIL
                </th>
                <th scope="col" className="px-6 py-3">
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody>
              {loading
                ? ""
                : users.length === 0
                ? "No users"
                : users.map((user) => (
                    <tr
                      key={user.id}
                      className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 ">{user.id}</td>
                      <td className="px-6 py-4"> {user.username}</td>
                      <td className="px-6 py-4">{user.phone_number}</td>

                      <td className="px-6 py-4">{user.email}</td>
                      <td className="px-6 py-4">
                        <div className="flex items-center gap-3">
                          <button
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                            onClick={() => {
                              setOpenView(true);
                              setSelecteduser(user);
                            }}
                          >
                            <AiFillEye size={30} />
                          </button>

                          <button
                            className="font-medium text-red-600 dark:text-red-500 hover:underline"
                            onClick={() => {
                              Swal.fire({
                                title: "Are you sure?",
                                text: "You won't be able to revert this!",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonColor: "#3085d6",
                                cancelButtonColor: "#d33",
                                confirmButtonText: "Yes, delete it!",
                              }).then((result) => {
                                if (result.isConfirmed) {
                                  deleteuser(user.id);
                                }
                              });
                            }}
                          >
                            <AiFillDelete size={30} />
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
          {loading && (
            <div className="h-40">
              <div className="w-full flex justify-center items-center h-full">
                <Spinner color="pink" className="h-12 w-12" />
              </div>
            </div>
          )}
        </div>

        <div className="flex justify-end items-center gap-5 p-5 mb-4">
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page - 1)}
          >
            prev
          </button>
          <div>{page}</div>
          <div>/</div>
          <div>{Math.ceil(count / 10)}</div>
          <button
            className="text-white bg-gradient-to-r from-pink-400 via-pink-500 to-pink-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={() => setPage(page + 1)}
          >
            next
          </button>
        </div>
      </div>
      {/* add user modal */}
      <Dialog open={openAdd} handler={() => setOpenAdd(!openAdd)}>
        <AdduserModal openAdd={openAdd} setOpenAdd={setOpenAdd} />
      </Dialog>
      <Dialog open={openView} handler={() => setOpenView(!openView)}>
        <ViewuserModal ID={selecteduser} />
      </Dialog>
    </div>
  );
};

export default Users;
